@charset "utf-8";
@import "../../../_various.scss";

/* ================================================================================ */
/*                                                                                  */
/*      スタイル初期化                                                              */
/*                                                                                  */
/* ================================================================================ */

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
form,
p,
table,
th,
td,
address,
select,
input {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/NotoSans/NotoSans_Regular.woff2") format("woff2"),
    url("../../fonts/NotoSans/NotoSans_regular.woff") format("woff"),
    url("../../fonts/NotoSans/NotoSans_regular.ttf") format("truetype"),
    url("../../fonts/NotoSans/NotoSans_regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: medium;
  font-weight: 500;
  src: url("../../fonts/NotoSans/NotoSans_Medium.woff2") format("woff2"),
    url("../../fonts/NotoSans/NotoSans_medium.woff") format("woff"),
    url("../../fonts/NotoSans/NotoSans_medium.ttf") format("truetype"),
    url("../../fonts/NotoSans/NotoSans_medium.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: bold;
  font-weight: 700;
  src: url("../../fonts/NotoSans/NotoSans_Bold.woff2") format("woff2"),
    url("../../fonts/NotoSans/NotoSans_bold.woff") format("woff"),
    url("../../fonts/NotoSans/NotoSans_bold.ttf") format("truetype"),
    url("../../fonts/NotoSans/NotoSans_bold.eot") format("embedded-opentype");
}

html {
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #fffdf4;
  color: #3D270F;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
  line-height: 1.48;
}

a img,
img {
  border: 0;
}

ul,
ol,
ul li,
ol li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: underline;
  color: #2382C7;
}

address {
  font-style: normal;
}

.att {
  color: #ff3333;
}

.description{
  padding: 0 16px;
}

.attention {
  padding: 0;
}

label {
  position: relative;
}

/* ================================================================================ */
/*                                                                                  */
/*      clearfix                                                                    */
/*                                                                                  */
/* ================================================================================ */

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

/* ================================================================================ */
/*                                                                                  */
/*      汎用スタイル                                                                */
/*                                                                                  */
/* ================================================================================ */

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

hr {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #ddd;
  height: 1px;
  margin: 5px 0;
}


// margin padding
.mt-0 {
  margin-top: 0;
}

@for $i from 1 through 25 {
  // top
  .mt-#{$i * 4} {
    margin-top: #{$i * 4}px;
  }
  .pt-#{$i * 4} {
    padding-top: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-mt-#{$i * 4} {
      margin-top: #{$i * 4}px;
    }
    .pc-pt-#{$i * 4} {
      padding-top: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-mt-#{$i * 4} {
      margin-top: #{$i * 4}px;
    }
    .sp-pt-#{$i * 4} {
      padding-top: #{$i * 4}px;
    }
  }
  // right
  .mr-#{$i * 4} {
    margin-right: #{$i * 4}px;
  }
  .pr-#{$i * 4} {
    padding-right: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-mr-#{$i * 4} {
      margin-right: #{$i * 4}px;
    }
    .pc-pr-#{$i * 4} {
      padding-right: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-mr-#{$i * 4} {
      margin-right: #{$i * 4}px;
    }
    .sp-pr-#{$i * 4} {
      padding-right: #{$i * 4}px;
    }
  }
  // bottom
  .mb-#{$i * 4} {
    margin-bottom: #{$i * 4}px;
  }
  .pb-#{$i * 4} {
    padding-bottom: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-mb-#{$i * 4} {
      margin-bottom: #{$i * 4}px;
    }
    .pc-pb-#{$i * 4} {
      padding-bottom: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-mb#{$i * 4} {
      margin-bottom: #{$i * 4}px;
    }
    .sp-pb#{$i * 4} {
      padding-bottom: #{$i * 4}px;
    }
  }
  // left
  .ml-#{$i * 4} {
    margin-left: #{$i * 4}px;
  }
  .pl-#{$i * 4} {
    padding-left: #{$i * 4}px;
  }
  @media screen and (min-width: 768px) {
    .pc-ml-#{$i * 4} {
      margin-left: #{$i * 4}px;
    }
    .pc-pl-#{$i * 4} {
      padding-left: #{$i * 4}px;
    }
  }
  @media screen and (max-width: 767px) {
    .sp-ml#{$i * 4} {
      margin-left: #{$i * 4}px;
    }
    .sp-pl#{$i * 4} {
      padding-left: #{$i * 4}px;
    }
  }
}

.d-inline_block {
  display: inline-block;
}

/*----------------------------------------------------------------【見出し】*/

h2 {
  padding: 0 16px;
  color: #3d270f;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.6;
}

h2.more {
  padding: 0;
}

#contents > h2,
#contents #tab1 > h2,
#contents #tab2 > h2 {
  margin-top: 24px;
}

.contents_m > h2.comp{
  margin-top: 24px;
}

/*----------------------------------------------------------------【外枠設定】*/

#wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

/* ================================================================================ */
/*                                                                                  */
/*      ヘッダーデザイン                                                            */
/*                                                                                  */
/* ================================================================================ */

header {
  padding: 12px 8px;
  background-color: #FFFFFF;
}

header #logo {
  float: left;
  height: 32px;
}

header #logo img {
  width: 180px;
}

header .entry,header .present {
  width: 40px;
  height: auto;
  float: right;
  margin-right: 10px;
  text-align: center;
  font-size: 1rem;
  text-decoration: none;
}
header .entry img,header .present img {
  width: 100%;
  max-width: 24px;
  margin-bottom: 3px;
}
header .entry{
  color: #FF7E41;
}
header .present{
  color: #3D270F;
}

header .menu {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 40px;
  height: 40px;
  text-decoration: none;
}

header .menuBtn {
  cursor: pointer;
  .border1,
  .border2,
  .border3 {
    fill: #3D270F;
    transition: .3s all ease;
    transform-origin: 24px 24px;
  }
  .changeBorder1 {
    transform: translate(25%, 0%) rotate(45deg);
  }
  .changeBorder2 {
    opacity: 0;
  }
  .changeBorder3 {
    transform: translate(-40%, 25%) rotate(-45deg);
  }
}

header .menu p {
  color: #3D270F;
  font-size: 1rem;
  line-height: 1;
}

header .new_message {
  position: absolute;
  right: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 80%;
  padding: 2px 4px;
  display: inline-block;
  background-color: #f03;
}

header .new_message span {
  display: none;
}

/* ================================================================================ */
/*                                                                                  */
/*      メニューデザイン                                                            */
/*                                                                                  */
/* ================================================================================ */

.l-offset {
  background-color: #F2F2F2;
  box-shadow: 0px 2px 4px rgba(54, 59, 64, .2);
  padding: 24px 16px;
  display: none;
}

.offsetClose {
  margin: 20px 0 0;
}

.offsetClose__btn {
  position: relative;
  display: block;
  padding: 14px 16px 14px 24px;
  background-color: #FFFFFF;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 6px;
  color: #828282;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
}


/* メニューパネル
----------------------------------------------- */

.myMenuPanel {
  margin: 0 0 10px;
}

.myMenuPanel__title {
  background-color: #555;
  background-image: url(../../img/sp/icon/user02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 8px;
  background-size: 24px;
  color: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0 10px 0 34px;
  font-size: 1.2rem;
}

.myMenuPanel__title a {
  color: #fff;
}

.myMenuPanel__title .fa {
  font-size: 20px;
  vertical-align: middle;
  margin: 0 5px 0 0;
}

.myMenuPanel__title__name {
  color: #ff7e41;
  margin: 0 5px;
}

.myMenuPanel__body {
  // padding: 10px;
  // background: #fff;
}

.myMenuList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
}

.myMenuList__item {
  width: 31%;
  margin-top: 8px;
}

.myMenuList__item + .myMenuList__item {
  margin-left: 8px;
}

.myMenuList__item > a {
  display: block;
  padding: 8px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, .4);
  text-decoration: none;
}

.myMenuList__item__icon {
  position: relative;
  display: block;
  height: 42px;
  width: 42px;
  margin: 4px auto 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.myMenuList__item__icon__cnt {
  position: absolute;
  top: 0;
  right: -4px;
  width: 16px;
  height: 16px;
  background: #FF7E41;
  color: #FFFFFF;
  font-size: 1rem;
  border-radius: 50%;
  padding: 1px;
  text-align: center;
}

.myMenuList__item__name {
  display: table;
  width: 100%;
}

.myMenuList__item__name > span {
  display: table-cell;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  color: #3D270F;
  height: 3em;
  line-height: 1.4em;
  vertical-align: middle;
}

.myMenuList__item__icon--message {
  background-image: url(../../img/sp/icon/mail.svg);
}

.myMenuList__item__icon--entry {
  background-image: url(../../img/sp/icon/time.svg);
}

.myMenuList__item__icon--resume {
  background-image: url(../../img/sp/icon/entry02.svg);
}

.myMenuList__item__icon--clip {
  background-image: url(../../img/sp/icon/note.svg);
}

.myMenuList__item__icon--edit {
  background-image: url(../../img/sp/icon/user.svg);
}

.myMenuList__item__icon--search {
  background-image: url(../../img/sp/mymenulist_search.png);
}

/* myMenuLinks */

.myMenuLinks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
}

.myMenuLinks_head {
  flex-wrap: nowrap;
  padding: 0 35px;
}

.myMenuLinks_body {
  width: 100%;
  margin: 16px 0;
  padding: 16px;
  background-color: #FFFFFF;
  border: 4px solid #FF7E41;
  border-radius: 4px;
  h2 {
    margin-bottom: 14px;
    padding: 0;
    border: none;
    background: none;
    color: #3D270F;
    font-size: 1.8rem;
    line-height: 1;
    text-align: center;
  }
  h2 svg {
    margin: 0 2px -4px 0;
  }
}

.myMenuLinks__item {
  display: flex;
  width: calc(100% / 2 - 5px);
  &-w100 {
    width: 100%;
    margin-bottom: 16px;
  }
}

.myMenuLinks__item > a {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 12px 48px 12px 16px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, .4);
  text-decoration: none;
  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    right: 16px;
    background-image: url(../../img/sp/icon/arrow/right-circle.svg);
    transform: translateY(-50%);
  }
}

.myMenuLinks__item--register + .myMenuLinks__item--login {
  margin-left: 16px;
}


.myMenuLinks__item--register a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
  background: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  &::before {
    content: none;
  }
  svg {
    margin-right: 4px;
  }
}

.myMenuLinks__item--login a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
  &::before {
    content: none;
  }
  svg {
    margin-right: 4px;
  }
}

.myMenuLinks__item--fresh a {
  background: #ddd;
  border: 1px solid #888;
}

.myMenuLinks__item--fresh a .fa {
  margin: 0 10px 0 0;
  font-size: 15px;
  vertical-align: top;
}

.myMenuLinks__item--fresh.active a {
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
}

.myMenuLinks__item--mid a {
  background: #ddd;
  border: 1px solid #888;
}

.myMenuLinks__item--mid.active a {
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
}

.myMenuLinks__item--mid.active a .fa {
  margin: 0 10px 0 0;
  font-size: 15px;
  vertical-align: top;
}

.l-offset .myMenuPanel__body {
  // background: rgba(255, 255, 255, .7);
}

.pageslide {
  /* These styles MUST be included. Do not change. */
  display: none;
  position: absolute;
  top: 64px;
  z-index: 999999;

  /* Specify the width of your pageslide here */
  width: 100%;

  /* These styles are optional, and describe how the pageslide will look */
  background-color: #F2F2F2;
  color: #2382C7;
  font-size: 1.6rem;
  font-weight: 700;
}

.myMenuList__2colBody {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .myMenuList__item {
    width: calc(50% - 4px);
  }
}

/* ================================================================================ */
/*                                                                                  */
/*      フッターデザイン                                                            */
/*                                                                                  */
/* ================================================================================ */

footer {
  margin-top: 70px;
  padding-top: 45px;
  position: relative;
  background-color: #FFFFFF;
  background-image: url("../../img/sp/common/footer_bg.png");
  background-repeat: repeat-x;
  background-size: 57px;
  .foot_inline .foot_menu {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }
  #pagetop {
    position: absolute;
    right: 5px;
    bottom: 282px;
  }
  .st-ftMenu {
    padding: 0 16px 16px 16px;
  }
  .st-ftMenu li {
    display: flex;
    align-items: center;
    width: 50%;
    height: 48px;
  }
  .st-ftMenu a {
    color: #3D270F;
    font-size: 1.6rem;
    text-decoration: none;
  }
  .pcBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 32px);
    height: 40px;
    margin: 0 auto 16px;
    background: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .ft-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    border-top: 1px solid #DBD5CC;
  }
  .ft-logo img {
    object-fit: contain;
    width: 160px;
    height: 40px;
  }
}

/* ================================================================================ */
/*                                                                                  */
/*      コンテンツデザイン                                                          */
/*                                                                                  */
/* ================================================================================ */

/*----------------------------------------------------------------【汎用デザイン】*/

#contents {
  background-color: #fffdf4;
}

#contents .head-description {
  margin: 24px 0;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
}

.contents_box {
  background: #fff;
  margin: 0 0 20px;
}

.main {
  // background: #ffffff;
  margin: 0 0 24px;
  padding: 16px 16px 0;
}

.main.search{
  padding: 0 16px 0;
}

.main.info{
  padding: 16px 20px;
}

.main table {
  width: 100%;
  line-height: 1.5em;
  margin-bottom: 20px;
  font-size: 10px;
}

.main table caption {
  background: #555;
  color: #fff;
  font-size: 10px;
  padding: 4px 5px;
}

.main table th {
  background: #f4f4f4;
  border: 1px solid #ccc;
  padding: 4px 5px;
}

.main table td {
  background: #fff;
  border: 1px solid #ccc;
  padding: 4px 5px;
}

.pageInformation {
  line-height: 1.6em;
  margin: .6em 0;
  font-size: 12px;
}

/*--------------------------------------------------------------求人検索パネル(トップ)*/

.mainVisual__img--sp {
  width: 100%;
  img {
    width: 100%;
  }
}

.jobSearchSelect {
  margin: 24px 0 0;
  padding: 0 16px;
}

.jobSearchSelect__switch {
  display: flex;
  margin: 0 -2px;
}

.jobSearchSelect__switch__title {
  padding: 0 2px;
  width: 50%;
}

.jobSearchSelect__switch__title a {
  display: block;
  padding: 10px 10px 8px;
  font-size: 11px;
  font-weight: bold;
  background: #d2d2d2;
  color: #666;
  text-decoration: none;
}

.jobSearchSelect__switch__title a .fa {
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.jobSearchSelect__switch__title--active a {
  background: #666;
  color: #fff;
}

.jobSearchSelect__body {
  padding: 16px;
  background: #FFFFFF;
  border: 4px solid #FF8951;
  border-radius: 4px;
}

.jobSearchF {
  display: flex;
  margin: 0 -5px;
}

.jobSearchF__title {
  width: 100%;
  margin: 0 0 24px 0;
  padding: 0;
  background: none;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

.jobSearchF__title > a {
  display: block;
  text-decoration: none;
  border: 1px solid #666;
  border-radius: 2px;
  background-image: linear-gradient(#fff 20%, #efefef);
  color: #333;
  padding: 10px;
}

.jobSearchF__title__icon {
  display: block;
  margin: 0 auto 5px;
  height: 40px;
}

.jobSearchF__title__icon .fa {
  line-height: 40px;
  font-size: 30px;
}

.jobSearchF__title__name {
  color: #333;
  font-size: 12px;
}

.jobSearchC {
  margin: 10px 0;
}

.jobSearchC__now p {
  padding: 3px;
  margin: 0;
  font-size: 11px;
  color: #666;
}

.jobsearchc__nowp:empty {
  display: none;
}

.jobSearchFW__ {
  display: block;
}

.jobSearchFW__text {
  flex-grow: 1;
  padding: 0 5px 0 0;
}

.jobSearchFW__text > input[type="text"] {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
  width: 100%;
}

.jobSearchFW__submit > input[type="submit"] {
  appearance: none;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  height: 30px;
  padding: 0 1em;
  border-radius: 3px;
  background: #333;
  border: 1px solid #333;
  color: #fff;
  cursor: pointer;
}

.cmn-stylishSelect {
  position: relative;
  & + & {
    margin-top: 16px;
  }
  select {
    width: 100%;
    height: 40px;
    appearance: none;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    padding: 8px 32px;
    font-size: 1.6rem;
    color: #828282;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  }
  .ic-before {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  .ic-arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}

.easySearchForm__submit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px auto 0;
  padding: 0;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  &::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 30px;
    width: 16px;
    height: 16px;
    background-image: url(../../img/icon/search02.svg);
    transform: translateY(-50%);
  }
}

.cmn-stylishBtn {
  appearance: none;
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 44px 0 52px;
  background-color: #FF7E41;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

/* add トップ search Tab
----------------------------------------------- */

.tp-search_TabBtn {
  display: flex;
  align-items: center;
  margin-top: 32px;
  .tab-recommend,
  .tab-new {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 64px;
    background-color: #F2F2F2;
    border-radius: 4px 4px 0 0;
    color: #828282;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .tab-recommend.isActive,
  .tab-new.isActive {
    background-color: #FF7E41;
    color: #FFFFFF;
  }
  .tab-recommend svg,
  .tab-new svg {
    fill: #828282;
    margin-right: 8px;
  }
  .tab-recommend.isActive svg,
  .tab-new.isActive svg {
    fill: #FFFFFF;
  }
}

.tp-search_TabContent {
  padding: 24px 0 24px 24px;
  background-color: #FFD0B9;
}

.tp-search_TabContent > section {
  display: none;
}

.tp-search_TabContent > section.isActive {
  display: block;
}

.tp-search_Tab-contents-recommend .item_list,
.tp-search_Tab-contents-new .item_list {
  display: flex;
  overflow-x: scroll;
  padding: 8px 0;
}

.moreBtn {
  margin-top: 24px;
  margin-right: 24px;
}

.moreBtn a {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 14px 8px 14px 0;
  background-color: #FF7E41;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* add トップ tp-special
----------------------------------------------- */

.tp-special {
  position: relative;
  margin-top: 24px;
  padding: 16px;
  background-color: #96C6E8;
  .tp-special_heading {
    margin-left: 70px;
  }
  .tp-special_heading h2 {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    padding: 0 0 0 16px;
    color: #ffffff;
    background-color: #2382C7;
    border-top: none;
    border-bottom: none;
    border-radius: 16px;
    font-size: 1.6rem;
    text-align: center;
  }
  .tp-special_heading h2::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 97%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 19px 16px 0;
    border-color: transparent #2382C7 transparent transparent;
    transform: translateY(-50%);
  }
  .tp-special_heading h2 span {
    margin-top: -4px;
    margin-right: 16px;
    font-size: 2.4rem;
    font-family: "Roboto", sans-serif;
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .tp-special_item {
    position: relative;
  }
  .tp-special_item + .tp-special_item {
    margin-top: 4px;
  }
  .tp-special_item a {
    display: flex;
    align-items: center;
    padding: 12px 48px 12px 16px;
    color: #2382C7;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(54, 59, 64, .1);
    font-size: 1.2rem;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      background: linear-gradient(0deg, rgba(150, 198, 232, .2), rgba(150, 198, 232, .2)), #FFFFFF;
    }
  }
  .tp-special_item svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

/* add top インタビュー
----------------------------------------------- */

#contents .contents_box.new.interview {
  margin: 40px 0 0;
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  border: none;
  h2.ic-mic {
    position: relative;
    padding: 0;
    border: none;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2.ic-mic svg {
    margin-right: 8px;
  }
  .body {
    padding-top: 0px;
  }
  .item_list {
    display: block;
    overflow-x: initial;
  }
  .item_list article {
    display: block;
    width: 100%;
  }
  .item_list article a {
    display: block;
    margin: 0;
    padding: 8px 10px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(35, 130, 199, .6);
    text-decoration: none;
  }
  .item_list article a .flex {
    display: flex;
  }
  .item_list article + article {
    margin-top: 8px;
    margin-left: 0;
  }
  .item_list article .img {
    width: 70px;
  }
  .item_list article .img img {
    object-fit: contain;
    width: 70px;
    height: 50px;
    border: none;
  }
  .item_list article .title {
    margin: 8px 0 0;
    padding: 0;
    color: #2382C7;
    font-size: 1.6rem;
    border-left: none;
  }
  .item_list article .cname {
    display: block;
    margin: 0;
    color: #000000;
    font-size: 1.2rem;
  }
  .item_list article .text h3 {
    width: 100%;
    margin-left: 8px;
    color: #2382C7;
    font-size: 16px;
  }
  .body > .more {
    margin-top: 24px;
    padding: 0;
    background: initial;
    text-align: center;
  }
  .body > .more a {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 14px 10px 14px 0;
    background-color: #FF7E41;
    color: #ffffff;
    border-radius: 6px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  .body > .more a svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.tp-contentWrap {
  padding: 0 16px;
}

#contents .contents_box.new,
#contents .contents_box.other,
#contents .contents_box.pickup {
  margin: 32px 0 0;
  padding: 12px 0 30px 10px;
  background-color: #FFF0DB;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  h2 {
    position: relative;
    padding: 0;
    border: none;
    background: inherit;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2.ic-company svg {
    margin-right: 8px;
  }
  .item_list {
    display: flex;
    overflow-x: scroll;
    margin-top: 16px;
    padding: 8px;
  }
}

#contents .contents_box.new_cuser {
  margin-top: 32px;
  padding: 12px 10px 30px;
  background-color: #FFF0DB;
  border-radius: 4px;
  border: none;
  h2 {
    position: relative;
    padding: 0;
    border: none;
    background: inherit;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
  }
  h2.ic-company svg {
    margin-right: 8px;
  }
  .item_list article + article {
    margin-left: 0;
  }
  .item_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item_list .body {
    display: flex;
    flex-wrap: wrap;
  }
  .item_list .price {
    display: none;
  }
  .item_list article {
    width: calc(100% / 2 - 4px);
    margin-top: 16px;
  }
  .item_list article a {
    width: 100%;
    display: block;
  }
  .item_list article img {
    width: 100%;
  }
  .title {
    margin-top: 8px;
    color: #2382C7;
    font-size: 1.2rem;
    line-height: 1.48;
  }
}

/* add トップ pickup
----------------------------------------------- */

.tp-pickup {
  margin-top: 32px;
  background-color: #96C6E8;
  border-radius: 4px;
  border-top: 8px solid #FF7E41;
  h2 {
    position: relative;
    padding: 12px 0 12px 16px;
    background: #2382C7;
    color: #ffffff;
    line-height: 1;
    border: none;
    margin-bottom: 0;
    font-size: 1.8rem;
  }
  h2::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 151px;
    height: 58px;
    background-image: url(../../img/sp/top/pickup_img.png);
    background-repeat: no-repeat;
    background-size: 151px 58px;
  }
  h2 span {
    font-size: 2.2rem;
  }
}

.tp-pickup_items {
  padding: 10px 8px;
  li + li {
    margin-top: 8px;
  }
}

.tp-pickup_item {
  position: relative;
  display: block;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, .6);
  text-decoration: none;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 54px 54px 0;
    border-color: transparent #FFF0DB transparent transparent;
    // border-top-right-radius: 4px;
  }
  &::after {
    content: "check!";
    position: absolute;
    top: 14px;
    right: 4px;
    color: #FF7E41;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    transform: rotate(45deg);
  }
  &_img {
    max-width: 84px;
  }
  &_img img {
    object-fit: contain;
    width: 84px;
  }
  &_txt {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  .cate {
    margin-bottom: 4px;
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .cate span {
    font-size: 1.2rem;
    font-weight: 400;
  }
  h3 {
    margin-top: 4px;
    margin-bottom: 0;
    padding: 0;
    border-left: none;
    color: #2382C7;
    font-size: 1.6rem;
  }
  p.tp-pickup_item_linkBtn {
    position: relative;
    display: block;
    margin-top: 12px;
    padding: 6px 64px 6px 56px;
    color: #FF7E41;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    &:hover {
      background-color: #FFF0DB;
    }
  }
  &_linkBtn svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  .flex {
    display: flex;
  }
}

/* add tp-read
----------------------------------------------- */

.tp-read {
  margin-top: 32px;
  &_heading {
    position: relative;
    display: flex;
  }
  &_heading > img {
    position: absolute;
    bottom: 0;
  }
  &_heading h2 {
    position: relative;
    width: 100%;
    margin-left: 90px;
    padding: 12px 18px;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 2.2rem;
    line-height: 1.4;
    border: none;
    margin-bottom: 0;
  }
  &_heading h2::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14.5px 15px 14.5px 0;
    border-color: transparent #FFD0B9 transparent transparent;
    transform: translateY(-50%);
  }
  &_txt {
    margin-top: 24px;
  }
  &_txt p {
    color: #3D270F;
    font-size: 1.6rem;
  }
  &_txt p + p {
    margin-top: 24px;
  }
}

/*--------------------------------------------------------------求人検索パネル(検索画面)*/

.jobSearchPanel {
  border: 1px solid #a17547;
  margin: 0 0 10px;
}

.jobSearchPanel__title {
  background: #a17547;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  position: relative;
}

.jobSearchPanel__title__toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.jobSearchPanel__title__set {
  position: relative;
  color: #fff;
}

.jobSearchPanel__title__set__icon {
  font-size: 14px;
  margin: 0 5px;
}

.jobSearchPanel__title__set__name {
  font-size: 12px;
  font-weight: bold;
}

.jobSearchPanel__title__set__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.jobSearchPanel__body {
  padding: 10px;
  background: #fff;
  display: none;
}

/* 検索フォーム
----------------------------------------------- */

.jobSearchForm {
  width: 100%;
}

.jobSearchForm__hint {
  color: #673a1b;
  font-size: 11px;
  margin: 10px 0 5px;
}

/* フォーム内の条件
----------------------------------------------- */

.searchCondition {
  margin: 0 0 9px;
  padding: 0 0 9px;
  border-bottom: 1px dashed #ccc;
}

.searchCondition__label {
  padding: 6px 0;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.searchCondition__label__more {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
  text-decoration: none;
}

.searchCondition__label .fa {
  color: #333;
  font-size: 18px;
  margin: 0 9px 0 0;
  vertical-align: middle;
}

.searchCondition__label span {
  font-size: 12px;
  font-weight: bold;
}

.searchCondition__setting__row + .searchCondition__setting__row {
  margin: 5px 0 0;
}

.searchCondition--horizontal {
  display: flex;
}

.searchCondition--horizontal .searchCondition__label {
  width: 100px;
}

.searchCondition--horizontal .searchCondition__setting {
  flex-grow: 1;
}

.searchCondition--submit .searchCondition__label {
  display: none;
}

.searchCondition--submit input[type="submit"] {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  height: 4em;
  border-radius: 3px;
  background: #ff9900;
  border: 1px solid #ff9900;
  background-image: linear-gradient(#fbaa31, #ff9900);
  color: #fff;
  cursor: pointer;
}

/* stylishLabel */

.stylishLabel label {
  position: relative;
  display: inline-block;
  margin: 5px 2px 5px 0;
}

.stylishLabel label input[type="checkbox"] {
  display: none;
}

.stylishLabel label input[type="checkbox"] + span {
  display: inline-block;
  padding: 4px 6px 4px 26px;
  position: relative;
  line-height: 1;
  font-size: 12px;
}

.stylishLabel label input[type="checkbox"] + span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
}

.stylishLabel label input[type="checkbox"]:checked + span::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  transform-origin: top left;
  transform: rotateZ(-45deg);
  width: 7px;
  height: 5px;
  border-bottom: 2px solid #ff9900;
  border-left: 2px solid #ff9900;
}

/* stylishSelect */

.stylishSelect {
  position: relative;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 34px;
  box-sizing: border-box;
}

.stylishSelect__btn {
  position: absolute;
  right: -1px;
  top: -1px;
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  background: #bbb;
  color: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-sizing: border-box;
}

.stylishSelect select {
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  padding: 0 10px;
  font-size: 12px;
  color: #666;
  width: 100%;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
}

/* stylishText */
.stylishText input[type="text"] {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 7px 10px;
  width: 100%;
  box-sizing: border-box;
}

/*----------------------------------------------------------------【フリーワード検索】*/
#contents .free_search {
  background: #eee;
  padding: 4px 0;
  margin: 0 0 10px;
  box-shadow: none;
}

#contents .free_search label {
  display: none;
}

#contents .free_search form {
  display: block;
  position: relative;
  height: 36px;
  overflow: hidden;
}

#contents .free_search form input[type="text"]#free {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  height: 36px;
  width: 100%;
  z-index: 1;
  background: #fff;
  font-size: 14px;
  padding: 8px 5px;
  border: none;
}

#contents .free_search form input[type="submit"].search_button {
  position: absolute;
  right: 0;
  z-index: 2;
  background: #333;
  color: #fff;
  border: none;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 65px;
  appearance: none;
  cursor: pointer;
}

/*----------------------------------------------------------------【求人詳細】*/
#contents h2.job_h2 span.cate {
  padding-right: 3px;
}

#contents h2.job_h2 span.pickup {
  position: absolute;
  top: 0;
  left: 0;
  background: #FF7E41;
  border-radius: 4px 0 0 0;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

#contents .date {
  font-size: 12px;
  text-align: right;
  padding: 5px;
}

#contents #social {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
  padding: 0 10px;
  div + div {
    margin-left: 16px;
  }
}

.sns-hatena {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/sp/common/sns_b.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.sns-twitter {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/common/sns_twi.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.sns-facebook {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/common/sns_fb.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.sns-line {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/common/sns_line.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

/* 特徴アイコン
----------------------------------------------- */
#contents .icon {
  padding: 10px;
}

#contents .icon li {
  margin: 0 4px 4px 0;
  display: inline-block;
  font-size: 11px;
  border: 1px solid #bbb;
  padding: 0 3px;
  white-space: nowrap;
}

#contents .icon li a {
  text-decoration: none;
}

/* flick
----------------------------------------------- */
#contents .flick {
  width: 100%;
  max-width: 300px;
  position: relative;
  overflow: auto;
  margin: 8px auto;
  @media screen and (max-width: 374px) {
    max-width: 248px;
  }
}

#contents .flick ul {
  display: block;
  margin: 0;
  padding: 0;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

#contents .flick ul li {
  float: left;
  padding: 0;
  width: 100%;
  max-width: 300px;
  border: 0;
  line-height: 1.4;
  @media screen and (max-width: 374px) {
    max-width: 248px;
  }
}

#contents .flick ul li p.img {
  margin: 0 4px;
  text-align: center;
  img {
    object-fit: contain;
    width: 100%;
    max-width: 300px;
    height: auto;
    @media screen and (max-width: 374px) {
      max-width: 248px;
    }
  }
}

#contents .flick ul li p.img span {
  line-height: 148px;
  display: block;
  background-color: #fafafa;
  border: 1px solid #ccc;
}

#contents .flick ul li p.text {
  padding: 0;
  margin: 0 12px;
  font-size: 12px;
}

#contents .navi {
  margin: 0;
}

#contents .navi a {
  position: absolute;
  top: 50%;
  text-indent: -9999px;
  overflow: hidden;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  transform: translateY(-50%);
}

#contents .nextbtn {
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  right: -10px;
  margin-right: 10px;
}

#contents .backbtn {
  background: url(../../img/sp/arrow3.png) no-repeat left;
  background-size: 20px 11px;
  left: -10px;
  margin-left: 10px;
}

#contents .nextbtn.inactive,
#contents .backbtn.inactive {
  opacity: .3;
}

#contents .indicator {
  position: relative;
  overflow: hidden;
  width: 13px;
  height: 5px;
  background: url(../../img/sp/indicater_bg.png) repeat-x left top;
  margin: 10px auto 10px auto;
}

#contents .indicator img {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

/*----------------------------------------------------------------【リストデザイン】*/

/* link_list
----------------------------------------------- */
.link_list ul li {
  border-bottom: 1px solid #ccc;
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  line-height: 1.5;
}

.link_list ul li a {
  display: block;
  padding: 7px 30px 7px 10px;
  text-decoration: none;
  color: #333;
}

.link_list ul li.image {
  background: none;
  text-align: center;
}

/* info_list
----------------------------------------------- */
#contents .info_list li {
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
  line-height: 1.5;
}

#contents .info_list ul li span {
  color: #3d270f;
  font-weight: bold;
}

#contents .info_list ul li p {
  padding: 0 10px;
  &.ic-phone {
    font-family: Roboto;
    font-size: 2.2rem;
    font-weight: 700;
  }
  &.ic-phone svg {
    margin-right: 4px;
  }
}

/* item_list
----------------------------------------------- */
#contents .item_list article {
  display: flex;
  align-items: stretch;
  width: 208px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(255, 126, 65, .6);
  a {
    display: block;
    padding: 16px;
    text-decoration: none;
    &:nth-child(n + 4) {
      display: none;
    }
  }
  img {
    object-fit: contain;
    width: 176px;
    height: 87.5px;
  }
  .cate {
    margin-top: 8px;
    color: #3D270F;
    font-size: 1.2rem;
  }
  h3,
  .title {
    margin-bottom: 0;
    padding: 0;
    border-left: none;
    color: #2382C7;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .title span {
    display: none;
  }
}

#contents .item_list article + article {
  margin-left: 16px;
}

#contents .item_list h3 {
  border: 0;
  padding: 0;
  margin-bottom: 5px;
}

#contents .item_list .price {
  font-size: 12px;
  color: #ff4574;
}

#contents .item_list .sub {
  font-size: 12px;
}

#contents .item_list .cate {
  font-size: 12px;
}

/* search_item */
// #contents .search_item article {
//   border-bottom: none;
//   background: none;
//   margin: 0 0 10px;
// }

// #contents .search_item article > a {
//   position: relative;
//   width: 100%;
//   padding: 24px 16px 0;
// }

#contents .search_item h3 {
  font-weight: bold;
  color: #666;
  font-size: 15px;
}

#contents .search_item h3 span {
  font-size: 12px;
  font-weight: normal;
}

#contents .search_item h3 .pickup {
  background-color: #dd1144;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
  padding: 3px;
  white-space: nowrap;
}

#contents .search_item .sub {
  font-size: 10px;
}

#contents .search_item table {
  width: 100%;
  font-size: 10px;
  clear: both;
  margin: 0;
}

#contents .search_item table th {
  border: 1px solid #ccc;
  padding: 3px;
  width: 58px;
  font-weight: normal;
  background-color: #fafafa;
}

#contents .search_item table td {
  border: 1px solid #ccc;
  padding: 3px 5px;
}

// custom search_list
#contents .search_item {
  article {
    width: 100%;
    flex-wrap: wrap;
    margin: 24px 0 0;
    padding: 0;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  article > a,article a.list {
    position: relative;
    width: 100%;
    padding: 0 16px;
    color: #3D270F;
    font-size: 1.6rem;
  }
  article + article {
  margin-left: 0;
  }
  .title {
    padding: 16px 24px 0;
    border-radius: 4px 4px 0px 0px;
  }
  .list_pickup.text {
    position: relative;
    margin: 0 -16px;
    padding: 24px 100px 8px 16px;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 4px 4px 0px 0px;
  }
  .list_pickup.text h3,
  .not_pickup.text h3 {
    color: #2382C7;
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
  }
  .sub {
    color: #3D270F;
    font-size: 1.2rem;
  }
  .img {
    margin: 16px auto 0;
    text-align: center;
  }
  .img img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  .not_pickup.text {
    position: relative;
    margin: 0 -16px;
    padding: 24px 100px 8px 16px;
  }
  .text {
    margin: 16px 0 0;
    padding: 0 24px 0;
    color: #3D270F;
    font-size: 1.6rem;
  }
  .text .pickup {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    background: #FF7E41;
    border-radius: 4px 0 0 0;
    color: #fff;
    padding: 2px 10px;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
  .list_pickup.text .pickup {
    left: 66px;
    border-radius: 0;
  }
  .list_pickup::before {
    content: "PICKUP";
    position: absolute;
    top: 0;
    left: 0;
    background: #2382C7;
    border-radius: 4px 0px 0px 0px;
    color: #fff;
    margin-right: 4px;
    padding: 2px 10px;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    z-index: 1;
  }
  p.gift {
    position: absolute;
    top: 0;
    right: 8px;
    padding: 20px 14px;
    background-color: #FB5555;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  p.gift::before {
    position: absolute;
    top: 100%;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 49px 0 0;
    border-color: #FB5555 transparent transparent transparent;
  }
  p.gift::after {
    position: absolute;
    top: 100%;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 49px 22px 0;
    border-color: transparent #FB5555 transparent transparent;
  }
  p.gift span {
    font-family: Roboto;
    font-size: 1.4rem;
  }
  .jobtext a p {
    margin: 24px 0 0;
    padding: 0;
    color: #3D270F;
    font-size: 1.6rem;
  }
  .jobtext a {
    padding: 0 16px;
  }
  .icon {
    margin-top: 16px;
    padding: 0 16px;
  }
  .icon li {
    margin: 8px 0 0 8px;
    display: inline-block;
    font-size: 1.2rem;
    border: none;
    padding: 0;
    white-space: nowrap;
  }
  .icon li a {
    position: relative;
    display: inline-block;
    padding: 6px 8px 6px 26px;
    color: #2382C7;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 16px 0px 0px 16px;
    border: none;
    font-size: 1.2rem;
    line-height: 1;
  }
  .icon li a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .info_table {
    margin-top: 24px;
    padding: 0 24px 0;
  }
  table {
    margin-top: 24px;
    border-collapse: separate;
    border-spacing: 0 7px;
    color: #3D270F;
    font-size: 1.2rem;
    font-weight: 400;
  }
  table > div {
    margin-top: 8px;
  }
  table th,
  table td {
    border: none;
  }
  table td {
    width: 58%;
  }
  table th {
    position: relative;
    width: 35%;
    padding: 8px 8px 8px 32px;
    background-color: #FFF0DB;
    font-weight: 400;
    text-align: left;
  }
  th::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 8px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  th.ic-job::before {
    background-image: url(../../img/sp/icon/job02.svg);
  }
  th.ic-wifi::before {
    background-image: url(../../img/sp/icon/wifi02.svg);
  }
  th.ic-company::before {
    background-image: url(../../img/sp/icon/company.svg);
  }
  th.ic-map::before {
    background-image: url(../../img/sp/icon/map02.svg);
  }
  td {
    padding: 8px 8px 8px 16px;
  }
  .button {
    width: 100%;
  }
  .button a {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 10px 10px 0;
    background-color: #FF7E41;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
  }
  .button a::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
}

/* entry_list
----------------------------------------------- */
#contents .entry_list {
  margin: 0 0 10px 0;
}

#contents .entry_list article {
  background-color: #FFFFFF;
  border-bottom: 1px solid #ccc;
  line-height: 1.5;
  padding: 7px 10px 0;
}

#contents .entry_list .img {
  text-align: center;
  padding-bottom: 10px;
}

#contents .entry_list h3 {
  border: 0;
  padding: 0;
  margin-bottom: 5px;
}

#contents .entry_list .state {
  font-weight: bold;
  padding-top: 10px;
}

#contents .entry_list .button {
  text-align: left;
  &.left {
    margin-left: -16px;
  }
}

#contents .entry_list .button a {
  width: auto;
  margin: 0;
  padding: 4px 16px;
  box-shadow: none;
  + a {
    margin-left: 4px;
  }
}

/* resume_list
----------------------------------------------- */

#contents .sch-detail._resumeList {
  margin: 24px 0 0;
  padding: 16px;
}


.resume_list {
  background-color: #FFFFFF;
}

#contents .resume_list article {
  border-bottom: 1px solid #ccc;
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  line-height: 1.5;
  display: table;
  width: 100%;
  padding-left: 8px;
}

#contents .resume_list label {
  width: 35px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#contents .resume_list label input[type="radio"] {
  margin-right: 3px;
  height: 25px;
  width: 25px;
  vertical-align: middle;
  margin-bottom: 5px;
}

#contents .resume_list .text {
  display: table-cell;
}

#contents .resume_list .text a {
  padding: 15px 20px 15px 0;
  display: block;
  text-decoration: none;
  color: #333;
}

#contents .resume_list .date {
  padding: 0;
  text-align: left;
}

/* mail_list
----------------------------------------------- */
#contents .description + .mail_list {
  border-top: 1px solid #ccc;
}

#contents .mail_list article a {
  color: #333;
}

#contents .mail_list article.open a {
  color: #999;
}

#contents .mail_list article {
  border-bottom: 1px solid #ccc;
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  line-height: 1.5;
  display: table;
  width: 100%;
}

#contents .mail_list article a.delete {
  width: 30px;
  display: table-cell;
  background: url(../../img/sp/delete.png) no-repeat 5px center;
  background-size: 16px 16px;
  text-indent: -9999px;
  overflow: hidden;
}

#contents .mail_list article .text {
  display: table-cell;
  padding: 0;
}

#contents .mail_list article .text a {
  padding: 10px 25px 10px 0;
  display: block;
  text-decoration: none;
}
#contents .mail_list article .text .mailtype {
  background-color: #333;
  color: #fff;
  font-size: 85%;
  padding: 3px;
  margin-right: 5px;
}

#contents .mail_list article.open .text .mailtype {
  background-color: #888;
}

#contents .mail_list article .text .title {
  clear: both;
}

#contents .mail_list article .text .content {
  font-size: 90%;
}

#contents .mail_list article .date {
  float: right;
  padding: 0;
}

/* message_list
----------------------------------------------- */
#contents .message_list article > a {
  text-decoration: none;
  color: #333;
  line-height: 1.4;
  display: block;
  padding: 10px;
  background: url(../../img/sp/arrow2.png) no-repeat right center;
  background-size: 20px 11px;
  padding-right: 35px;
}
#contents .message_list {
  background-color: #FFFFFF;
}

#contents .message_list article {
  border-bottom: 1px solid #ccc;
  line-height: 1.5;
}

#contents .message_list article h3 {
  line-height: 1.4;
}

#contents .message_list article .message_content {
  margin: 0 5px 24px 10px;
}

#contents .message_list article .message {
  padding: 10px;
  font-size: 11px;
}

#contents .message_list article .mailtype {
  font-size: 11px;
  padding: 2px 5px;
  margin-right: 5px;
  font-weight: normal;
  background: #ddd;
  border-radius: 3px;
}

#contents .message_list article .maildate {
  font-size: 11px;
}

#contents .message_list article .from {
  font-size: 11px;
}

#contents .message_list article p.file {
  line-height: 1.5;
  background-color: #eee;
}

#contents .message_list article p.file a {
  display: block;
  background: url(../../img/sp/file.png) no-repeat 10px center;
  background-size: 30px 16px;
  padding: 2px 10px 2px 45px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

#contents .message_list article .mail_action {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  background-color: #ddd;
}

#contents .message_list article .mail_action input[type="button"] {
  padding: 5px 10px;
  font-size: 12px;
}

#contents .description + .mail_list {
  margin-top: 16px;
  background: #fff;
}

/*----------------------------------------------------------------【フォーム】*/

/* item検索タブ切り替え
----------------------------------------------- */
#contents #tab2 {
  display: none;
}

#contents h2 .search,
#contents h2 .back {
  float: right;
  padding: 8px 12px;
  // margin-right: 16px;
  display: inline-block;
  background: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

// #contents h2 .back {
//   padding: 3px 10px;
//   margin-right: 5px;
//   text-decoration: none;
//   color: #fff;
//   font-weight: bold;
//   display: inline-block;
//   text-align: center;
//   background: #666;
//   font-size: 12px;
//   white-space: nowrap;
//   float: right;
// }

/* フォーム送信ボタン
----------------------------------------------- */
#contents .button {
  margin: 16px 0;
  padding: 0 16px;
  text-align: center;
}

#contents .button-flex{
  display: flex;
  justify-content: space-between;
  padding: 0;
}

#contents .button input {
  appearance: none;
  display: inline-block;
  width: fit-content;
  max-width: 240px;
  margin: 0;
  padding: 14px 40px 14px 32px;
  background-color: #ff7e41;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

#contents .button input + input {
  margin: 0 0 0 16px;
}

#contents .button input.fit {
  width: 240px;
}

#contents .button input.btn_back + input.fit {
  margin: 16px 0 0;
}

#contents .button li + li {
  margin: 16px 0 0;
}

#contents .button input.btn_back + input.btn_regist,
#contents .button input.btn_back + input.btn_check {
  margin-top: 16px;
  @media screen and (min-width: 560px) {
    margin-left: 16px;
  }
}

#contents .button input.btn_back {
  padding: 14px 32px 14px 40px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/left-circle-back.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 8px;
  background-size: 16px;
  border: 2px solid rgba(54, 59, 64, .4);
  border-radius: 6px;
  box-shadow: none;
  color: rgba(54, 59, 64, .4);
  font-size: 1.6rem;
}

#contents .button a {
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: inline-block;
  appearance: none;
  background-color: #ff7e41;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.button-svgIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

#contents .button .not {
  background: #ff7e41;
  color: #fff;
}

#contents .button a + a {
  margin-top: 8px;
}

#contents .button .btn_check {
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

#contents .button .btn_check[disabled="disabled"] {
  width: 95%;
  background-color: #ddd;
  color: #888;
}

#contents .button button {
  cursor: pointer;
  border: solid 1px #ccc;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  outline: none;
  text-align: center;
  font-size: 14px;
  appearance: none;
  background: #fff;
}

/* 入力画面
----------------------------------------------- */
#contents .form {
  margin: 16px 0;
  padding-bottom: 16px;
  background-color: #fff;
}

#contents .form ul li {
  margin: 0 0 16px;
}

#contents .form ul li > span {
  display: block;
  margin: 0 0 8px;
  padding: 8px;
  background-color: #fff0db;
  color: #3d270f;
  font-size: 1.6rem;
  font-weight: 700;
}

#contents .form ul li > span + p {
  padding: 8px;
}

/* フォームパーツ */

#contents .form ul li input[type="text"],
#contents .form ul li input[type="email"],
#contents .form ul li input[type="number"],
#contents .form ul li input[type="tel"],
#contents .form ul li input[type="password"],
#contents .form ul li select,
#contents .form ul li textarea {
  appearance: none;
  padding: 8px;
  color: #3D270F;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  font-size: 1.6rem;
}

#contents .form {
  input[name=zip1] {
    margin-right: 4px;
  }
  .zip-mark {
    margin-right: 8px;
    font-size: 1.6rem;
  }
  select[name=add_sub],
  select[name=has_add_sub] {
    margin-left: 8px;
  }
}

#contents .form ul li select {
  display: inline-block;
  min-width: 100px;
  // margin: 8px 0;
  padding-right: 30px;
  appearance: none;
  background-image: url(../../img/sp/icon/arrow/bottom.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
  color: #828282;
  & + & {
    margin-left: 8px;
  }
}

#contents .form ul li p.fix-height select,
#contents .form ul li  p.fix-height input{
  margin: 0 8px 0 0;
  height: 40px;
}

#contents .form ul li textarea {
  width: 90%;
}

#contents .form ul li input[type="button"] {
  display: block;
  width: 200px;
  margin: 16px 0;
  padding: 6px 16px 6px 24px;
  background-color: #fff;
  background-image: url(../../img/sp/icon/map02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

input[type=radio] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1px;
  width: 16px;
  height: 16px;
  margin: 0;
  background-color: #F2F2F2;
  border-radius: 50%;
  transform: translateY(-50%);
  &:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  &:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #3D270F;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
  &:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  &:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    width: 7px;
    height: 14px;
    border-radius: 4px;
    transform: rotate(40deg);
    border-bottom: 3px solid #3D270F;
    border-right: 3px solid #3D270F;
  }
}


#contents .form ul li .check label,
#contents .form ul li .radio label,
#contents .form ul li > p label {
  position: relative;
  display: inline-block;
  margin: 0 12px 0 0;
  padding-left: 24px;
}

#contents .form ul li .check {
  padding-bottom: 8px;
  label {
  width: 100%;
  }
}

#contents .form ul li .check label + label {
  margin-top: 8px;
}

#contents .form ul li .addHopeList {
  position: relative;
  margin: 16px 0;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  color: #828282;
}

#contents .form ul li .addHopeList span {
  position: absolute;
  top: 50%;
  right: 8px;
  display: inline-block;
  padding: 6px 12px 6px;
  background-color: #FFFFFF;
  border-radius: 6px;
  background-image: url(../../img/sp/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border: 2px solid rgba(54, 59, 64, .4);
  color: rgba(54, 59, 64, .4);
  font-size: 1.2rem;
  font-weight: 700;
  transform: translateY(-50%);
}

/* エラーフラグ */
#contents .form ul li .validate input[type="text"],
#contents .form ul li .validate input[type="password"],
#contents .form ul li .validate textarea,
#contents .form ul li .validate select {
  background-color: #FFF2F2;
  box-shadow: 0px 0px 6px #FB5555;
}

#contents .form ul li .error {
  padding: 0;
  margin: 0 5px 16px;
  border: none;
  line-height: 1.4;
  font-size: 1.2rem;
  background: none;
}

#contents .form ul li.message-err .error {
  padding: 0 0 0 8px;
}

/* エラーメッセージ包括 */
#contents .error {
  line-height: 1.5;
  color: #ff3333;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ff3333;
  background-color: #fff;
}

/* 登録ヒント */
#contents .form ul li span.hint {
  background: transparent;
  color: #666;
  margin: 8px 0 0 5px;
  font-size: 10px;
  display: block;
  line-height: 1.4;
  font-weight: normal;
}

#contents .form ul li span.hint + input,
#contents .form ul li span.hint + textarea,
#contents .form ul li span.hint + select {
  margin-top: 8px;
}

/* 必須マーク */
#contents .form ul li span.requisite {
  margin-left: 8px;
  padding: 4px 6px;
  background-color: #FB5555;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1;
}

/* メールアドレス仮登録 */
#contents .form .resMail {
  border: 1px solid #ff3333;
  padding: 10px;
  margin: 5px;
  color: #ff3333;
}

/* 確認画面
----------------------------------------------- */
#contents .form_check {
  margin: 0 0 10px 0;
  padding: 16px 8px;
  background-color: #FFFFFF;
  // h3 {

  // }
}

#contents .form_check ul {
  margin: 0 0 20px;
}

#contents .form_check ul li {
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
  line-height: 1.5;
}

#contents .form_check ul li span {
  color: #3D270F;
  font-weight: 700;
}

#contents .form_check ul li p {
  font-size: 1.6rem;
  padding: 0 8px;
}

/*----------------------------------------------------------------【メッセージ】*/
#contents .message {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0;
}

#contents .message p {
  margin: 5px 0;
}

#contents .message ul {
  text-align: center;
  padding: 10px 0;
}

#contents .message ul li a {
  display: inline-block;
  width: 240px;
  padding: 14px 24px 14px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-main.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

#contents .message ul li + li a {
  margin-top: 10px;
}

/*----------------------------------------------------------------【エラーメッセージ】*/
#contents .error_message,
#contents .resMail {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid #FB5555;
  border-radius: 4px;
  color: #FB5555;
  font-size: 1.6rem;
}

#contents .error_message p {
  margin: 5px 0;
}

#contents .error_message ul {
  text-align: center;
  padding: 10px 0;
}

#contents .error_message ul li a {
  display: inline-block;
  width: 240px;
  padding: 11px 24px 11px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-main.svg);
  background-position: top 50% right 16px;
  background-repeat: no-repeat;
  background-size: 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

#contents .error_message ul li + li a {
  margin-top: 10px;
}

/*----------------------------------------------------------------【ページャー】*/

#contents .search_page {
  padding: 20px 0;
  text-align: center;
}

#contents .search_page p {
  font-size: 12px;
  margin: 0 0 5px 0;
}

#contents .search_page p span {
  font-weight: bold;
  font-size: 14px;
  margin: 0 3px;
}

#contents .search_page li {
  line-height: 24px;
  display: inline;
  padding: 3px 0;
}

#contents .search_page li.focus {
  padding: 3px 7px;
  font-weight: bold;
}

#contents .search_page li a {
  text-decoration: none;
  padding: 3px 7px;
  color: #333;
  border: 1px solid #ddd;
}

#contents .search_page li.back a {
  background: #ddd;
}

#contents .search_page li.page a {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
}

#contents.search_pagelia:hover {
  background: #333;
  color: #eee;
  border: 1px solid #000;
}

/* ページャー
----------------------------------------------- */
#contents .search_page {
  margin: 16px 0 0;
}

#contents  .search_page p {
  color: #3D270F;
  font-size: 1.2rem;
  span {
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 700;
  }
}

#contents  .search_page ul {
  margin: 24px -16px 0;
  text-align: center;
}

#contents  .search_page ul li {
  position: relative;
  display: inline-block;
  line-height: 1;
  &.page {
    font-family: Roboto;
  }
  &.back {
    margin-right: 8px;
  }
  &.next {
    margin-left: 8px;
    margin-right: 0;
  }
  &.back a,
  &.next a {
    padding: 8px 24px 8px 12px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }
  &.back a {
    padding: 8px 12px 8px 24px;
  }
  &.back a svg {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  &.next a svg {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}

#contents .search_page ul li.focus {
  padding: 8px 11.42px;
  background: #FF7E41;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: Roboto;
  font-weight: 700;
}

#contents .search_page ul li a {
  display: inline-block;
  padding: 8px 11.42px;;
  background: #F2F2F2;
  border-radius: 4px;
  color: #828282;
  font-weight: 700;
  text-decoration: none;
}

/*----------------------------------------------------------------【sub_menu】*/

#contents ul.sub_menu {
  padding: 10px;
  margin-bottom: -1px;
  border-bottom: 1px solid #ccc;
}

#contents ul.sub_menu li {
  display: inline-block;
  margin: 0 2px;
}

#contents ul.sub_menu li a {
  display: inline-block;
  padding: 8px 12px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

/* ニュース
----------------------------------------------- */
#contents .contents_box.news,
#contents .contents_box.page {
  margin-top: 32px;
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 0;
  h2 {
    position: relative;
    margin-bottom: 0;
    padding: 0;
    background: none;
    border-bottom: none;
    border: none;
    color: #3D270F;
    font-size: 2.2rem;
    text-align: center;
    text-decoration: none;
  }
  h2 svg {
    margin-right: 8px;
  }
  h2 a {
    color: #3D270F;
    text-decoration: none;
  }
  .body {
    padding: 0;
  }
  ul {
    margin-top: 16px;
  }
  ul li {
    position: relative;
    display: flex;
    min-height: 48px;
    align-items: center;
    margin-bottom: 8px;
    padding: 12px 50px 12px 12px;
    color: #2382C7;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(54, 59, 64, .1);
    font-size: 1.2rem;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      background: linear-gradient(0deg, rgba(150, 198, 232, .2), rgba(150, 198, 232, .2)), #FFFFFF;
    }
  }
  ul li::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 16px;
    height: 16px;
    background-image: url(../../img/sp/icon/arrow/right-circle.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  ul li a {
    color: #2382C7;
    text-decoration: none;
  }
  p {
    padding: 0;
  }
}

#contents .contents_box.page {
  margin: 32px 0 0;
  .link_list ul li {
    background-image: none;
  }
  .text {
    padding: 0;
  }
  .text a {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 48px;
    align-items: center;
    padding: 12px 50px 12px 12px;
    font-size: 1.2rem;
  }
}

/*----------------------------------------------------------------【ニュース本文】*/

#contents .news_text {
  padding: 20px 10px;
  line-height: 1.4;
}

#contents .news_text p {
  margin: 5px 0;
}

#contents .news_text ul {
  text-align: center;
  padding: 10px 0;
}

#contents .news_text ul li a {
  width: 80%;
  padding: 7px 20px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  outline: none;
  text-align: center;
  font-size: 14px;
  appearance: none;
  background: #fff;
  border: solid 1px #ccc;
}

#contents .news_text ul li + li a {
  margin-top: 10px;
}

/*----------------------------------------------------------------【ページ本文】*/

/* ページ本文
----------------------------------------------- */
.main .page_text {
  padding: 10px;
  background: #ffffff;
  font-size: 10px;
  line-height: 1.4em;
}

.main .page_text h3 {
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 0 1em;
  background: #555;
  color: #fff;
}

.main .page_text h4 {
  font-size: 12px;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 0 1em;
  background: #eee;
  color: #333;
}

.main .page_text ul {
  margin: 1em 0 2em 2em;
}

.main .page_text ul li {
  list-style: disc outside;
  margin: 0 0 .5em;
}

.main .page_text ol {
  margin: 1em 0 2em 2em;
}

.main .page_text ol li {
  list-style: desimal outside;
  margin: 0 0 .5em;
}

.main .page_text p {
  margin: 0 0 2em;
  font-size: 1.6rem;
  line-height: 1.48;
}

.main .page_text .info_table {
  padding: 0;
}

.qj-qanda {
  margin: 10px 0px 10px;
}

.qj-qanda dl {
  margin: 0 0 10px;
}

.qj-qanda dl dt {
  position: relative;
  padding: 0 0 0 30px;
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.3em;
}

.qj-qanda dl dd {
  position: relative;
  padding: 0 0 10px 30px;
  margin: 0 0 10px;
  border-bottom: 1px dashed #bbb;
  font-size: 1.6rem;
  line-height: 1.48;
}

.qj-qanda dl dd::after,
.qj-qanda dl dt::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  border-radius: 2px;
}

.qj-qanda dl dt::after {
  content: "Q";
  background-color: #2382c7;
}
.qj-qanda dl dd::after {
  content: "A";
  background-color: #ff7e41;
}

.qj-pageBtn {
  text-align: center;
  margin: 0 0 20px;
}

.qj-pageBtn a {
  display: inline-block;
  padding: 1em 2em;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1.3em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
}

.qj-pageBtn a + a {
  margin: 0 0 0 .8em;
}

.qj-pagebtna:hover {
  opacity: .7;
  color: #fff;
}

/*----------------------------------------------------------------【インタビュー】*/

#contents .interview-wrap {
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  border: none;
  .interview-heading {
    margin-bottom: 16px;
    text-align: center;
  }
  .interview-heading svg {
    margin-right: 8px;
  }
  .item_list article {
    width: 100%;
    margin: 0;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(35, 130, 199, .6);
    text-decoration: none;
  }
  .item_list article a {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 8px 16px;
  }
  .item_list article + article {
    margin-top: 8px;
  }
  .item_list article .img {
    width: 70px;
  }
  .item_list article .img img {
    object-fit: contain;
    width: 70px;
    height: 50px;
    border: none;
  }
  .item_list article .text {
    margin-left: 8px;
  }
  .item_list article .text h3 {
    width: 100%;
    margin-top: 8px;
    color: #2382C7;
    font-size: 16px;
  }
  .item_list article .cname {
    display: block;
    width: 100%;
    margin: 0;
    color: #000000;
    font-size: 1.2rem;
  }
}

/* メイン：企業インタビュー(詳細)
----------------------------------------------- */

#contents .interview_detail {
  .interview_photo {
    margin-top: 24px;
    background-color: #fffdf4;
    text-align: center;
  }
  .interview_photo img {
    object-fit: contain;
    width: 320px;
    height: 140px;
  }
  .interview_description {
    position: relative;
    width: calc(100% - 90px);
    margin: 24px 0 0 90px;
    padding: 16px 24px;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 1.8rem;
    font-weight: 700;
  }
  .interview_description::before {
    content: "";
    position: absolute;
    top: 50%;
    right: calc(100% + 16px);
    width: 72.5px;
    height: 65px;
    background-image: url(../../img/sp/top/icon-taxi.png);
    background-repeat: no-repeat;
    background-size: 72.5px 65px;
    transform: translateY(-50%);
  }
  .interview_description::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14.5px 15px 14.5px 0;
    border-color: transparent #FFD0B9 transparent transparent;
    transform: translateY(-50%);
  }
  .info_list {
    margin-top: 24px;
    padding: 16px;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 4px;
  }
  .info_list ul {
    background: #fff;
    padding: 16px 12px;
    border-radius: 4px;
  }
  .info_list li {
    padding: 0;
    border-bottom: none;
  }
  .info_list ul li .info_list_heading {
    display: block;
    margin: 8px 0;
    padding: 0;
    color: #2382C7;
    font-size: 1.6rem;
  }
  .info_list p {
    margin: 0 0 24px;
    padding: 0;
    font-size: 1.6rem;
  }
  .info_list p img {
    display: block;
    object-fit: contain;
    width: 100%;
    max-width: 310px;
    height: 180px;
    margin: 8px auto;
  }
  .interview_date {
    margin: 40px 0 0;
    font-size: 1.2rem;
    text-align: right;
  }
}

// old
// .interview_photo {
//   margin: 0 0 10px;
// }

// .interview_photo img {
//   max-width: 100%;
//   height: auto;
// }

// .interview_description {
//   margin: 0 0 10px;
//   font-size: 12px;
// }

// .interview_description2 {
//   margin: 0 0 10px;
//   padding: 10px;
//   font-size: 12px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
// }

// #contents .interview_detail .info_list {
//   border-top: 1px solid #ddd;
//   margin: 0 0 10px;
// }

// #contents .interview_detail .info_list ul li {
//   padding: 10px 10px 20px;
// }

// #contents .interview_detail .info_list ul li span {
//   display: block;
//   margin: 0 0 10px;
// }

// #contents .interview_detail .info_list ul li p img {
//   max-width: 100%;
//   height: auto;
// }

/*----------------------------------------------------------------【質問詳細】*/
#contents .faq_item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

#contents .faq_item .head_info {
  margin-bottom: 5px;
}

#contents .faq_item .head_info .item_img {
  float: left;
  width: 45px;
}

#contents .faq_item .head_info .item_img img {
  width: 35px;
  height: 35px;
}

#contents .faq_item .head_info .item_img .q {
  background: url(../../img/sp/qa_q.gif) no-repeat left top;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  display: block;
}

#contents .faq_item .head_info .item_img .a {
  background: url(../../img/sp/qa_a.gif) no-repeat left top;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  display: block;
}

#contents .faq_item .head_info .user_name {
  margin-left: 45px;
}

#contents .faq_item .head_info .time {
  display: block;
  font-size: 12px;
}

#contents .faq_item span.img {
  display: block;
  margin-top: 10px;
}

#contents .faq_item .supplement {
  margin-top: 10px;
}

#contents .faq_item ul.outline {
  border-top: 1px dashed #999;
  margin-top: 10px;
}

#contents .faq_item ul.outline li {
  width: 49%;
}

#contents .faq_item ul.outline li.point {
  text-align: left;
}

#contents .faq_item ul {
  padding: 5px 0;
}

#contents .faq_item ul li {
  display: inline-block;
  font-size: 12px;
}

#contents .faq_item ul li {
  width: 49%;
  display: inline-block;
  font-size: 12px;
}

#contents .faq_item ul li.point {
  text-align: right;
}

#contents.faq_itemulli:only-of-type {
  display: block;
  width: auto;
}

#contents .faq_item .faq_category {
  font-size: 12px;
}

#contents .faq_item .thank {
  border: 1px dotted #ff7e7e;
  padding: 10px;
  margin-top: 5px;
}

#contents .faq_item .faq_box h3 {
  margin-bottom: 5px;
}

#contents .faq_item .faq_box {
  font-size: 16px;
  word-break: break-all;
}

/* この質問に対する回答
----------------------------------------------- */
#contents #answer_list {
  margin-top: 5px;
}

#contents #answer_list h2 a {
  font-size: 14px;
  margin-left: 5px;
}

#contents #answer_list ul {
  border: 0;
  margin: 0;
  padding: 5px 0 0;
}

#contents #answer_list li {
  display: block;
  text-align: right;
  width: auto;
}

#contents #answer_list .thank h3 {
  font-size: 12px;
}

#contents #answer_list .controller4Answer {
  float: left;
}

/*----------------------------------------------------------------【お祝い金申請】*/
#contents .item_list table td.giftBotton a {
  background: #666;
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 3px 0;
}

.gift-btn {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 10px 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}
.gift-btn::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

/*----------------------------------------------------------------検索一覧フォーム*/
#contents .sch-from_contents {
  margin-top: 34px;
  dt {
    position: relative;
    padding: 6px 12px 6px 36px;
    background-color: #FFF0DB;
    font-size: 1.8rem;
    font-weight: 700;
  }
  dt span {
    color: #FF7E41;
  }
  dt::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  dt.ic-job::before {
    background-image: url(../../img/sp/icon/job.svg);
  }
  dt.ic-wifi::before {
    background-image: url(../../img/sp/icon/wifi.svg);
  }
  dt.ic-feature::before {
    background-image: url(../../img/sp/icon/feature.svg);
  }
  dt.ic-key::before {
    background-image: url(../../img/sp/icon/key.svg);
  }
  dt.ic-company::before {
    background-image: url(../../img/sp/icon/company02.svg);
  }
  dt.ic-map::before {
    background-image: url(../../img/sp/icon/map.svg);
  }
  dt.ic-money::before {
    background-image: url(../../img/sp/icon/money.svg);
  }
  dd {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
  }
  dd label {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 24px;
    color: #3D270F;
    cursor: pointer;
    font-size: 1.6rem;
  }
  dd label:last-of-type {
    margin-bottom: 0;
  }
  dd label input[type=checkbox] {
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #F2F2F2;
    border-radius: 4px;
  }
  dd label input[type=checkbox]:checked {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  }
  dd label input[type=checkbox]:checked::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 5px;
    width: 7px;
    height: 14px;
    border-radius: 4px;
    transform: rotate(40deg);
    border-bottom: 3px solid #3D270F;
    border-right: 3px solid #3D270F;
  }
  input[type="submit"].btn_search {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 48px;
    padding: 0 0 0 10px;
    background: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 700;
  }
  input[name=free],
  input[name=cuser_name] {
    appearance: none;
    width: 100%;
    max-width: 100%;
    height: 40px;
    padding: 8px 24px;
    background: #FFFFFF;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    font-size: 1.6rem;
  }
  input[name=free]::placeholder {
    color: #828282;
  }
  .button {
    position: relative;
    width: 240px;
    height: 48px;
    margin: 40px auto 0;
    padding: 0;
  }
  .button svg {
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    z-index: 1;
  }
  select {
    width: 184px;
    height: 40px;
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    margin: 0 16px 16px 0;
    padding: 0 10px;
    font-size: 1.6rem;
    color: #828282;
    box-shadow: 0 0 6px rgba(54, 59, 64, .4);
    background-image: url(../../img/sp/icon/arrow/bottom.svg);
    background-repeat: no-repeat;
    background-position: top 50% right 8px;
    background-size: 16px;

  }
  select + select{
    margin: 0 16px 0 0;
  }
  select[name=salary_type]{
    margin: 0 16px 0 0;
  }
  .japan_select {
    position: relative;
    display: block;
    width: 184px;
  }
  .money_select {
    position: relative;
  }
  .sub-txt {
    font-size: 1.6rem;
  }
  .salary_select {
    position: relative;
    margin-top: 16px;
  }
}

#contents .sch-detail {
  position: relative;
  margin: 24px 16px 0;
  padding: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
  .job_h2 {
    display: flex;
    padding: 20px 100px 0 16px;
    border-radius: 4px 4px 0px 0px;
    color: #3D270F;
    font-size: 2.2rem;
    line-height: 1.4;
  }
  .list_pickup.text .pickup {
    left: 0;
    border-radius: 0;
  }
  .list_pickup::before {
    content: "PICKUP";
    position: absolute;
    top: 0;
    left: 66px;
    background: #2382C7;
    border-radius: 4px 0px 0px 0px;
    color: #fff;
    margin-right: 4px;
    padding: 2px 10px;
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    z-index: 1;
  }
  p.gift {
    position: absolute;
    top: -8px;
    right: 16px;
    padding: 20px 14px;
    background-color: #FB5555;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  p.gift::before {
    position: absolute;
    top: 100%;
    left: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 49px 0 0;
    border-color: #FB5555 transparent transparent transparent;
  }
  p.gift::after {
    position: absolute;
    top: 100%;
    right: 0;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 49px 22px 0;
    border-color: transparent #FB5555 transparent transparent;
  }
  p.gift span {
    font-family: Roboto;
    font-size: 1.4rem;
  }
  h2.job_h2 span.cate {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 400;
  }
  .description {
    margin: 24px 0 0;
    padding: 0 16px;
    color: #3D270F;
    font-size: 1.6rem;
  }
  .icon {
    margin-top: 16px;
    padding: 0;
  }
  .icon li {
    margin: 8px 0 0 8px;
    display: inline-block;
    font-size: 1.2rem;
    border: none;
    padding: 0;
    white-space: nowrap;
  }
  .icon li a {
    position: relative;
    display: inline-block;
    padding: 6px 8px 6px 26px;
    color: #2382C7;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 16px 0px 0px 16px;
    border: none;
    font-size: 1.2rem;
    line-height: 1;
  }
  .icon li a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
  }
  .info_list {
    margin-top: 24px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
  }
  .info_list h3 {
    position: relative;
    margin-top: 24px;
    padding: 0 0 0 28px;
    background-color: #ffffff;
    color: #3D270F;
    font-size: 2.2rem;
    font-weight: 700;
  }
  .info_list h3 svg {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 6px;
  }
  ul {
    margin-bottom: 16px;
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 400;
  }
  ul li {
    border-bottom: none;
    padding: 0;
  }
  ul span {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 16px;
    padding: 8px;
    background-color: #FFF0DB;
    color: #3D270F;
    font-weight: 700;
  }
  ul span.mt-0 {
    margin-top: 0;
  }
  span::before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  span.ic-job::before {
    background-image: url(../../img/sp/icon/job02.svg);
  }
  span.ic-wifi::before {
    background-image: url(../../img/sp/icon/wifi02.svg);
  }
  span.ic-company::before {
    background-image: url(../../img/sp/icon/company.svg);
  }
  span.ic-map::before {
    background-image: url(../../img/sp/icon/map02.svg);
  }
  p {
    margin-top: 8px;
    padding: 0;
  }
  .button {
    margin: 24px auto 0;
    padding: 0;
    background-color: #FFFFFF;
  }
  .btn_clip,
  .btn_clip.not,
  .btn_inquiry,
  .btn_inquiry.not {
    position: relative;
    width: 100%;
    padding: 10px 0 10px 10px;
    background-color: #FFFFFF;
    border: 2px solid #FF7E41;
    border-radius: 6px;
    color: #FF7E41;
    font-size: 16px;
    text-align: center;
  }
  .btn_clip::before,
  .btn_clip.not::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../../img/sp/icon/add.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  .btn_inquiry::before,
  .btn_inquiry.not::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../../img/sp/icon/mail02.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  .button .btn_entry {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 16px;
    padding: 10px 0;
    background-color: #FF7E41;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    &:hover {
      opacity: .8;
    }
  }
  .button .btn_entry::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    right: 16px;
    background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
    transform: translateY(-50%);
  }
  .btn_clip_out,
  .btn_clip_out.not {
    position: relative;
    // width: 240px;
    padding: 10px 10px 10px 16px;
    background-color: #FFFFFF;
    border: 2px solid rgba(54, 59, 64, .4);
    box-shadow: none;
    border-radius: 6px;
    color: rgba(54, 59, 64, .4);
    font-size: 1.6rem;
    text-align: center;
  }
  .btn_clip_out::before,
  .btn_clip_out.not::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 16px;
    width: 16px;
    height: 16px;
    background-image: url(../../img/sp/icon/close.svg);
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
}

/* 固定ページ系
----------------------------------------------- */
.page-contents {
  margin-top: 16px;
  padding-bottom: 30px;
  background: #fffdf4;
  &_heading {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  &_heading img {
    position: absolute;
    bottom: 0;
  }
  &_heading h4 {
    position: relative;
    width: 100%;
    margin-left: 90px;
    padding: 18px 22px;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 1.8rem;
    text-align: center;
  }
  &_heading h4::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14.5px 15px 14.5px 0;
    border-color: transparent #FFD0B9 transparent transparent;
    transform: translateY(-50%);
  }
  &_head h3 {
    margin: 0 -16px;
    padding: 0;
    border-left: none;
    text-align: center;
  }
  &_head h3 img {
    width: 100%;
  }
  &_head p {
    margin-top: 24px;
    padding: 0;
  }
  &_point,
  &_step,
  &_qa {
    margin-top: 28px;
  }
  &_step h4 {
    position: relative;
    background-color: #FFD0B9;
    border-radius: 6px;
  }
  &_qa h4 {
    position: relative;
    background-color: #FFD0B9;
    border-radius: 6px;
    color: #3D270F;
    font-size: 1.8rem;
    font-weight: 700;
  }
  &_foot {
    margin-top: 48px;
  }
  &_foot .page-entryBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 24px 40px 24px 24px;
    background-color: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #FFFFFF;
    font-size: 2.2rem;
    font-weight: 700;
    text-decoration: none;
    word-break: keep-all;
    text-align: center;
  }
  &_foot .page-entryBtn svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &_foot .page-registBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 18px 18px 18px 44px;
    background-color: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #FFFFFF;
    font-size: 2.2rem;
    font-weight: 700;
    text-decoration: none;
  }
  &_foot .page-registBtn:hover {
    opacity: .8;
  }
  &_foot .page-registBtn svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
}

.page-pointItems,
.page-stepItems,
.page-qaItems {
  margin-top: 24px;
}

.page-stepItem {
  padding-bottom: 16px;
  & + & {
    margin-top: 40px;
  }
  &_head {
    position: relative;
    display: block;
    background-color: #FFF0DB;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
  }
  &:not(:first-child) &_head::before {
    content: " ";
    position: absolute;
    top: -43%;
    left: 50%;
    width: 0;
    height: 0;
    margin-top: 16px;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #FFD0B9 transparent transparent transparent;
    transform: translate(-50%, 43%);
  }
  &_head span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    background-color: #FFD0B9;
    font-size: 2.2rem;
  }
  &_head p {
    padding: 16px;
  }
  &_head .page-registBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 244px;
    height: 40px;
    margin: 30px auto 0;
    padding: 0 16px 0 24px;
    background-color: #FF7E41;
    border-radius: 6px;
    box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
    color: #FFFFFF;
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: none;
  }
  &_head .page-registBtn svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
  &_txt {
    margin-top: 16px;
  }
  &_txt strong {
    color: #FF7E41;
  }
  &_txt span {
    display: block;
    margin-top: 24px;
    font-size: 1.2rem;
  }
}

.page-qaItems {
  &_row + &_row {
    margin-top: 24px;
  }
  dl {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px ;
  }
  dt {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 106px;
    padding: 24px 24px 24px 46px;
    background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
    border-radius: 4px 4px 0 0;
    font-size: 1.8rem;
    font-weight: 700;
  }
  dt::before {
    position: absolute;
    top: 48%;
    left: 16px;
    content: "Q";
    color: #2382C7;
    font-family: Roboto;
    font-size: 2.4rem;
    font-weight: 700;
    transform: translateY(-48%);
  }
  dd {
    position: relative;
    width: 100%;
    padding: 24px 24px 24px 46px;
    font-size: 1.8rem;
  }
  dd::before {
    position: absolute;
    top: 18px;
    left: 16px;
    content: "A";
    color: #FF7E41;
    font-family: Roboto;
    font-size: 2.4rem;
    font-weight: 700;
  }
  dd span {
    display: block;
    margin-top: 24px;
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

.page-pointItem {
  width: 100%;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
  border-radius: 4px;
  & + & {
    margin-top: 16px;
  }
  &_capHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    color: #2382C7;
    font-family: Roboto;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
  }
  &_capHead span {
    margin-top: -15px;
    font-size: 5.5rem;
    line-height: 1;
  }
  figure {
    margin: 0;
  }
  figure img {
    width: 100%;
    margin-top: 20px;
    vertical-align: bottom;
  }
  figcaption {
    padding: 0 24px;
    font-size: 1.8rem;
  }
  figcaption strong {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 62px;
    margin-bottom: 16px;
    font-size: 2.6rem;
    line-height: 1.2;
  }
}
.saiyo-pointItems {
  margin-top: 24px;
}

.saiyo-pointItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 200px;
  background-color: #FFF0DB;
  border-radius: 4px;
  line-height: 1.48;
  & + & {
    margin-top: 16px;
  }
  strong {
    font-size: 4rem;
    text-align: center;
  }
  &_txtLg {
    color: #FB5555;
    font-family: Roboto;
    font-size: 12rem;
    line-height: 1;
  }
  &_txtSm {
    font-size: 3rem;
  }
  &_crMain {
    color: #FF7E41;
  }
}

.saiyo-point_txt {
  p {
    margin-top: 32px;
  }
  p span {
    color: #FF7E41;
  }
  &_large {
    font-size: 2.4rem;
    text-align: center;
    word-break: keep-all;
  }
}

/* 固定ページ凡用
----------------------------------------------- */
.other {
  .bread {
    margin: 24px -16px;
    padding: 0 16px 16px;
    background: #fffdf4;
  }
  h2 {
    margin-top: 24px;
    padding-left: 0;
  }
}

.page_box {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, .4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0 0;
  dl {
    margin-bottom: 30px;
  }
  dt {
    margin: 24px 0 10px;
  }
  p {
    margin: 0 0 16px;
  }
  ul {
    padding-left: 1em;
  }
  ul li {
    margin-bottom: 4px;
    list-style: disc;
  }
}

/* 詳細ページテーブル（info_table）
----------------------------------------------- */
.info_table {
  margin-top: 24px;
  table {
    color: #3D270F;
    font-size: 1.6rem;
    font-weight: 400;
  }
  table > div {
    margin-top: 8px;
  }
  table th,
  table td {
    border: 1px solid #E0E0E0;
  }
  table th {
    position: relative;
    width: 25%;
    padding: 8px;
    background-color: #FFF0DB;
    font-weight: 700;
  }
  td {
    padding: 8px;
  }
}

// 地図を見るリンク
#contents .sch-detail ul span.googlemap,
.googlemap {
  position: relative;
  display: inline;
  width: initial;
  margin-left: 8px;
  padding: 0 0 0 14px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/map.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 12px 12px;
  font-weight: 400;
}

/* ボタン
----------------------------------------------- */
#contents .button button {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 16px 11px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
}

#contents .button a.btn-main {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 24px 11px 16px;
  background-color: #FF7E41;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, .4);
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  &._note {
    padding: 11px 16px 11px 24px;
    background-image: url(../../img/sp/icon/note03.svg);
    background-repeat: no-repeat;
    background-position: center left 16px;
  }
}

#contents .button a.btn-sub {
  position: relative;
  display: block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 24px 11px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-main.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: none;
  color: #FF7E41;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
}

#contents .button .btn-sub_mini {
  position: relative;
  display: block;
  margin: auto;
  padding: 8px 12px 8px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: none;
  color: #FF7E41;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  &._toggle {
    margin: auto -16px auto auto;
  }
}

#contents .button a.btn-sub_back {
  position: relative;
  display: block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 16px 11px 24px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/left-circle-main.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: none;
  color: #FF7E41;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
  &._w320 {
    max-width: 320px;
  }
}

#contents .button {
  a.btn-main + a.btn-sub,
  a.btn-main + a.btn-main,
  a.btn-sub + a.btn-sub {
    margin-top: 16px;
  }
}

button.btn-Border,
#contents .button a.btn-Border {
  display: block;
  width: auto;
  margin: 16px 0;
  padding: 6px 16px;
  background-color: #fff;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
  &._shadowNone {
    box-shadow: none;
  }
}

.btn-unsubscribe,
#contents .button input.btn-unsubscribe {
  position: relative;
  display: block;
  width: 240px;
  margin: auto;
  padding: 10px 24px 10px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-gray.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, .4);
  box-shadow: none;
  color: rgba(54, 59, 64, .4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

// 応募系
#contents .mail_list article .text .mailtype,
#contents .message_list article .mailtype {
  color: #2382c7;
  background: linear-gradient(0deg, rgba(150, 198, 232, .3), rgba(150, 198, 232, .3)), #FFFFFF;
}

.btn-Shadow{
  box-shadow: 0px 0px 6px rgba(54, 59, 64, .4);
}

.form-Alart_Normal{
  background: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, .4);
  box-sizing: border-box;
  border-radius: 4px;
}

#contents .contents_box.news.mt-0 {
  margin-top: 0;
}

.btn_cuser_inquiry.not {
  margin: 0 0 24px;
}

#contents .sch-detail._p-16 {
  margin: 24px 0 0;
  padding: 0 16px;
}

#contents .contents_box.news {
  .info_list li {
    border-bottom: none;
  }
  .message {
    border: none;
  }
}

.description .att {
  color: #3d270f;
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 700;
}

// info fixed buttons
#contents .info-fixedFoot {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  padding: 16px 12px;
  background-color: #DFEEF8;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, .4);
  z-index: 10;
  .btn_tel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 104px;
    height: 40px;
    padding: 0 8px 0 20px;
    background-color: #FF7E41;
    background-image: url(../../img/sp/icon/phone02.svg);
    background-repeat: no-repeat;
    background-position: center left 8px;
    background-size: 16px 16px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    @media screen and (max-width: 374px){
      min-width: 88px;
    }
  }
  .btn_entry,
  .btn_entry.not {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 104px;
    height: 40px;
    padding: 0 8px 0 20px;
    background-color: #FF7E41;
    background-image: url(../../img/sp/icon/entry.svg);
    background-repeat: no-repeat;
    background-position: center left 8px;
    background-size: 16px 16px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    @media screen and (max-width: 374px){
      min-width: 96px;
    }
  }
  .btn_clip,
  .btn_clip.not {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 104px;
    height: 40px;
    padding: 0 8px 0 20px;
    background-color: #FFFFFF;
    background-image: url(../../img/sp/icon/add.svg);
    background-repeat: no-repeat;
    background-position: center left 8px;
    background-size: 16px 16px;
    border-radius: 6px;
    border: solid 2px #FF7E41;
    color: #FF7E41;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
  }
  .btn_clip_out,
  .btn_clip_out.not {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 104px;
    height: 40px;
    padding: 0 8px 0 20px;
    background-color: #FFFFFF;
    background-image: url(../../img/sp/icon/close.svg);
    background-repeat: no-repeat;
    background-position: center left 8px;
    background-size: 16px 16px;
    border-radius: 6px;
    border: 2px solid rgba(54, 59, 64, .4);
    color: rgba(54, 59, 64, .4);
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
  }
  a + a {
    margin-left: 8px;
  }
}

.gift-form select {
  display: block;
}

.gift-form .select-wrap span {
	margin: 0 16px;
}
.gift-form .select-wrap {
	display: flex;
	align-items: center;
}

.gift-form .select-wrap {
  display: block;
}
.gift-form .select-wrap > div:not(:first-child) {
  margin-top: 24px;
}
.gift-form .select-wrap > div {
  display: flex;
  align-items: center;
}
