@charset "UTF-8";
/** フォント関連 **/
/* 日本語のみ */
/* 英語のみ */
/* 使用ウェイト
Roboto-Bold,
Roboto Condensed,
Noto -regular,bold,
*/
/** 色設定 **/
/** 透明度の設定 **/
/** トランジションに関しての設定 **/
/* 秒、イージングの設定 */
/* ※ イージング早見表 https://easings.net/ja */
/* ================================================================================ */
/*                                                                                  */
/*      スタイル初期化                                                              */
/*                                                                                  */
/* ================================================================================ */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
form,
p,
table,
th,
td,
address,
select,
input {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/NotoSans/NotoSans_Regular.woff2") format("woff2"), url("../../fonts/NotoSans/NotoSans_regular.woff") format("woff"), url("../../fonts/NotoSans/NotoSans_regular.ttf") format("truetype"), url("../../fonts/NotoSans/NotoSans_regular.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: medium;
  font-weight: 500;
  src: url("../../fonts/NotoSans/NotoSans_Medium.woff2") format("woff2"), url("../../fonts/NotoSans/NotoSans_medium.woff") format("woff"), url("../../fonts/NotoSans/NotoSans_medium.ttf") format("truetype"), url("../../fonts/NotoSans/NotoSans_medium.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  font-style: bold;
  font-weight: 700;
  src: url("../../fonts/NotoSans/NotoSans_Bold.woff2") format("woff2"), url("../../fonts/NotoSans/NotoSans_bold.woff") format("woff"), url("../../fonts/NotoSans/NotoSans_bold.ttf") format("truetype"), url("../../fonts/NotoSans/NotoSans_bold.eot") format("embedded-opentype");
}

html {
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #fffdf4;
  color: #3D270F;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", "Roboto", sans-serif;
  line-height: 1.48;
}

a img,
img {
  border: 0;
}

ul,
ol,
ul li,
ol li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: underline;
  color: #2382C7;
}

address {
  font-style: normal;
}

.att {
  color: #ff3333;
}

.description {
  padding: 0 16px;
}

.attention {
  padding: 0;
}

label {
  position: relative;
}

/* ================================================================================ */
/*                                                                                  */
/*      clearfix                                                                    */
/*                                                                                  */
/* ================================================================================ */
.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

/* ================================================================================ */
/*                                                                                  */
/*      汎用スタイル                                                                */
/*                                                                                  */
/* ================================================================================ */
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

hr {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #ddd;
  height: 1px;
  margin: 5px 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.pt-4 {
  padding-top: 4px;
}

@media screen and (min-width: 768px) {
  .pc-mt-4 {
    margin-top: 4px;
  }
  .pc-pt-4 {
    padding-top: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-4 {
    margin-top: 4px;
  }
  .sp-pt-4 {
    padding-top: 4px;
  }
}

.mr-4 {
  margin-right: 4px;
}

.pr-4 {
  padding-right: 4px;
}

@media screen and (min-width: 768px) {
  .pc-mr-4 {
    margin-right: 4px;
  }
  .pc-pr-4 {
    padding-right: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-4 {
    margin-right: 4px;
  }
  .sp-pr-4 {
    padding-right: 4px;
  }
}

.mb-4 {
  margin-bottom: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

@media screen and (min-width: 768px) {
  .pc-mb-4 {
    margin-bottom: 4px;
  }
  .pc-pb-4 {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb4 {
    margin-bottom: 4px;
  }
  .sp-pb4 {
    padding-bottom: 4px;
  }
}

.ml-4 {
  margin-left: 4px;
}

.pl-4 {
  padding-left: 4px;
}

@media screen and (min-width: 768px) {
  .pc-ml-4 {
    margin-left: 4px;
  }
  .pc-pl-4 {
    padding-left: 4px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml4 {
    margin-left: 4px;
  }
  .sp-pl4 {
    padding-left: 4px;
  }
}

.mt-8 {
  margin-top: 8px;
}

.pt-8 {
  padding-top: 8px;
}

@media screen and (min-width: 768px) {
  .pc-mt-8 {
    margin-top: 8px;
  }
  .pc-pt-8 {
    padding-top: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-8 {
    margin-top: 8px;
  }
  .sp-pt-8 {
    padding-top: 8px;
  }
}

.mr-8 {
  margin-right: 8px;
}

.pr-8 {
  padding-right: 8px;
}

@media screen and (min-width: 768px) {
  .pc-mr-8 {
    margin-right: 8px;
  }
  .pc-pr-8 {
    padding-right: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-8 {
    margin-right: 8px;
  }
  .sp-pr-8 {
    padding-right: 8px;
  }
}

.mb-8 {
  margin-bottom: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .pc-mb-8 {
    margin-bottom: 8px;
  }
  .pc-pb-8 {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb8 {
    margin-bottom: 8px;
  }
  .sp-pb8 {
    padding-bottom: 8px;
  }
}

.ml-8 {
  margin-left: 8px;
}

.pl-8 {
  padding-left: 8px;
}

@media screen and (min-width: 768px) {
  .pc-ml-8 {
    margin-left: 8px;
  }
  .pc-pl-8 {
    padding-left: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml8 {
    margin-left: 8px;
  }
  .sp-pl8 {
    padding-left: 8px;
  }
}

.mt-12 {
  margin-top: 12px;
}

.pt-12 {
  padding-top: 12px;
}

@media screen and (min-width: 768px) {
  .pc-mt-12 {
    margin-top: 12px;
  }
  .pc-pt-12 {
    padding-top: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-12 {
    margin-top: 12px;
  }
  .sp-pt-12 {
    padding-top: 12px;
  }
}

.mr-12 {
  margin-right: 12px;
}

.pr-12 {
  padding-right: 12px;
}

@media screen and (min-width: 768px) {
  .pc-mr-12 {
    margin-right: 12px;
  }
  .pc-pr-12 {
    padding-right: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-12 {
    margin-right: 12px;
  }
  .sp-pr-12 {
    padding-right: 12px;
  }
}

.mb-12 {
  margin-bottom: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .pc-mb-12 {
    margin-bottom: 12px;
  }
  .pc-pb-12 {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb12 {
    margin-bottom: 12px;
  }
  .sp-pb12 {
    padding-bottom: 12px;
  }
}

.ml-12 {
  margin-left: 12px;
}

.pl-12 {
  padding-left: 12px;
}

@media screen and (min-width: 768px) {
  .pc-ml-12 {
    margin-left: 12px;
  }
  .pc-pl-12 {
    padding-left: 12px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml12 {
    margin-left: 12px;
  }
  .sp-pl12 {
    padding-left: 12px;
  }
}

.mt-16 {
  margin-top: 16px;
}

.pt-16 {
  padding-top: 16px;
}

@media screen and (min-width: 768px) {
  .pc-mt-16 {
    margin-top: 16px;
  }
  .pc-pt-16 {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-16 {
    margin-top: 16px;
  }
  .sp-pt-16 {
    padding-top: 16px;
  }
}

.mr-16 {
  margin-right: 16px;
}

.pr-16 {
  padding-right: 16px;
}

@media screen and (min-width: 768px) {
  .pc-mr-16 {
    margin-right: 16px;
  }
  .pc-pr-16 {
    padding-right: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-16 {
    margin-right: 16px;
  }
  .sp-pr-16 {
    padding-right: 16px;
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .pc-mb-16 {
    margin-bottom: 16px;
  }
  .pc-pb-16 {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb16 {
    margin-bottom: 16px;
  }
  .sp-pb16 {
    padding-bottom: 16px;
  }
}

.ml-16 {
  margin-left: 16px;
}

.pl-16 {
  padding-left: 16px;
}

@media screen and (min-width: 768px) {
  .pc-ml-16 {
    margin-left: 16px;
  }
  .pc-pl-16 {
    padding-left: 16px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml16 {
    margin-left: 16px;
  }
  .sp-pl16 {
    padding-left: 16px;
  }
}

.mt-20 {
  margin-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

@media screen and (min-width: 768px) {
  .pc-mt-20 {
    margin-top: 20px;
  }
  .pc-pt-20 {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-20 {
    margin-top: 20px;
  }
  .sp-pt-20 {
    padding-top: 20px;
  }
}

.mr-20 {
  margin-right: 20px;
}

.pr-20 {
  padding-right: 20px;
}

@media screen and (min-width: 768px) {
  .pc-mr-20 {
    margin-right: 20px;
  }
  .pc-pr-20 {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-20 {
    margin-right: 20px;
  }
  .sp-pr-20 {
    padding-right: 20px;
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .pc-mb-20 {
    margin-bottom: 20px;
  }
  .pc-pb-20 {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb20 {
    margin-bottom: 20px;
  }
  .sp-pb20 {
    padding-bottom: 20px;
  }
}

.ml-20 {
  margin-left: 20px;
}

.pl-20 {
  padding-left: 20px;
}

@media screen and (min-width: 768px) {
  .pc-ml-20 {
    margin-left: 20px;
  }
  .pc-pl-20 {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml20 {
    margin-left: 20px;
  }
  .sp-pl20 {
    padding-left: 20px;
  }
}

.mt-24 {
  margin-top: 24px;
}

.pt-24 {
  padding-top: 24px;
}

@media screen and (min-width: 768px) {
  .pc-mt-24 {
    margin-top: 24px;
  }
  .pc-pt-24 {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-24 {
    margin-top: 24px;
  }
  .sp-pt-24 {
    padding-top: 24px;
  }
}

.mr-24 {
  margin-right: 24px;
}

.pr-24 {
  padding-right: 24px;
}

@media screen and (min-width: 768px) {
  .pc-mr-24 {
    margin-right: 24px;
  }
  .pc-pr-24 {
    padding-right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-24 {
    margin-right: 24px;
  }
  .sp-pr-24 {
    padding-right: 24px;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .pc-mb-24 {
    margin-bottom: 24px;
  }
  .pc-pb-24 {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb24 {
    margin-bottom: 24px;
  }
  .sp-pb24 {
    padding-bottom: 24px;
  }
}

.ml-24 {
  margin-left: 24px;
}

.pl-24 {
  padding-left: 24px;
}

@media screen and (min-width: 768px) {
  .pc-ml-24 {
    margin-left: 24px;
  }
  .pc-pl-24 {
    padding-left: 24px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml24 {
    margin-left: 24px;
  }
  .sp-pl24 {
    padding-left: 24px;
  }
}

.mt-28 {
  margin-top: 28px;
}

.pt-28 {
  padding-top: 28px;
}

@media screen and (min-width: 768px) {
  .pc-mt-28 {
    margin-top: 28px;
  }
  .pc-pt-28 {
    padding-top: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-28 {
    margin-top: 28px;
  }
  .sp-pt-28 {
    padding-top: 28px;
  }
}

.mr-28 {
  margin-right: 28px;
}

.pr-28 {
  padding-right: 28px;
}

@media screen and (min-width: 768px) {
  .pc-mr-28 {
    margin-right: 28px;
  }
  .pc-pr-28 {
    padding-right: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-28 {
    margin-right: 28px;
  }
  .sp-pr-28 {
    padding-right: 28px;
  }
}

.mb-28 {
  margin-bottom: 28px;
}

.pb-28 {
  padding-bottom: 28px;
}

@media screen and (min-width: 768px) {
  .pc-mb-28 {
    margin-bottom: 28px;
  }
  .pc-pb-28 {
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb28 {
    margin-bottom: 28px;
  }
  .sp-pb28 {
    padding-bottom: 28px;
  }
}

.ml-28 {
  margin-left: 28px;
}

.pl-28 {
  padding-left: 28px;
}

@media screen and (min-width: 768px) {
  .pc-ml-28 {
    margin-left: 28px;
  }
  .pc-pl-28 {
    padding-left: 28px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml28 {
    margin-left: 28px;
  }
  .sp-pl28 {
    padding-left: 28px;
  }
}

.mt-32 {
  margin-top: 32px;
}

.pt-32 {
  padding-top: 32px;
}

@media screen and (min-width: 768px) {
  .pc-mt-32 {
    margin-top: 32px;
  }
  .pc-pt-32 {
    padding-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-32 {
    margin-top: 32px;
  }
  .sp-pt-32 {
    padding-top: 32px;
  }
}

.mr-32 {
  margin-right: 32px;
}

.pr-32 {
  padding-right: 32px;
}

@media screen and (min-width: 768px) {
  .pc-mr-32 {
    margin-right: 32px;
  }
  .pc-pr-32 {
    padding-right: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-32 {
    margin-right: 32px;
  }
  .sp-pr-32 {
    padding-right: 32px;
  }
}

.mb-32 {
  margin-bottom: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

@media screen and (min-width: 768px) {
  .pc-mb-32 {
    margin-bottom: 32px;
  }
  .pc-pb-32 {
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb32 {
    margin-bottom: 32px;
  }
  .sp-pb32 {
    padding-bottom: 32px;
  }
}

.ml-32 {
  margin-left: 32px;
}

.pl-32 {
  padding-left: 32px;
}

@media screen and (min-width: 768px) {
  .pc-ml-32 {
    margin-left: 32px;
  }
  .pc-pl-32 {
    padding-left: 32px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml32 {
    margin-left: 32px;
  }
  .sp-pl32 {
    padding-left: 32px;
  }
}

.mt-36 {
  margin-top: 36px;
}

.pt-36 {
  padding-top: 36px;
}

@media screen and (min-width: 768px) {
  .pc-mt-36 {
    margin-top: 36px;
  }
  .pc-pt-36 {
    padding-top: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-36 {
    margin-top: 36px;
  }
  .sp-pt-36 {
    padding-top: 36px;
  }
}

.mr-36 {
  margin-right: 36px;
}

.pr-36 {
  padding-right: 36px;
}

@media screen and (min-width: 768px) {
  .pc-mr-36 {
    margin-right: 36px;
  }
  .pc-pr-36 {
    padding-right: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-36 {
    margin-right: 36px;
  }
  .sp-pr-36 {
    padding-right: 36px;
  }
}

.mb-36 {
  margin-bottom: 36px;
}

.pb-36 {
  padding-bottom: 36px;
}

@media screen and (min-width: 768px) {
  .pc-mb-36 {
    margin-bottom: 36px;
  }
  .pc-pb-36 {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb36 {
    margin-bottom: 36px;
  }
  .sp-pb36 {
    padding-bottom: 36px;
  }
}

.ml-36 {
  margin-left: 36px;
}

.pl-36 {
  padding-left: 36px;
}

@media screen and (min-width: 768px) {
  .pc-ml-36 {
    margin-left: 36px;
  }
  .pc-pl-36 {
    padding-left: 36px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml36 {
    margin-left: 36px;
  }
  .sp-pl36 {
    padding-left: 36px;
  }
}

.mt-40 {
  margin-top: 40px;
}

.pt-40 {
  padding-top: 40px;
}

@media screen and (min-width: 768px) {
  .pc-mt-40 {
    margin-top: 40px;
  }
  .pc-pt-40 {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-40 {
    margin-top: 40px;
  }
  .sp-pt-40 {
    padding-top: 40px;
  }
}

.mr-40 {
  margin-right: 40px;
}

.pr-40 {
  padding-right: 40px;
}

@media screen and (min-width: 768px) {
  .pc-mr-40 {
    margin-right: 40px;
  }
  .pc-pr-40 {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-40 {
    margin-right: 40px;
  }
  .sp-pr-40 {
    padding-right: 40px;
  }
}

.mb-40 {
  margin-bottom: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .pc-mb-40 {
    margin-bottom: 40px;
  }
  .pc-pb-40 {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb40 {
    margin-bottom: 40px;
  }
  .sp-pb40 {
    padding-bottom: 40px;
  }
}

.ml-40 {
  margin-left: 40px;
}

.pl-40 {
  padding-left: 40px;
}

@media screen and (min-width: 768px) {
  .pc-ml-40 {
    margin-left: 40px;
  }
  .pc-pl-40 {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml40 {
    margin-left: 40px;
  }
  .sp-pl40 {
    padding-left: 40px;
  }
}

.mt-44 {
  margin-top: 44px;
}

.pt-44 {
  padding-top: 44px;
}

@media screen and (min-width: 768px) {
  .pc-mt-44 {
    margin-top: 44px;
  }
  .pc-pt-44 {
    padding-top: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-44 {
    margin-top: 44px;
  }
  .sp-pt-44 {
    padding-top: 44px;
  }
}

.mr-44 {
  margin-right: 44px;
}

.pr-44 {
  padding-right: 44px;
}

@media screen and (min-width: 768px) {
  .pc-mr-44 {
    margin-right: 44px;
  }
  .pc-pr-44 {
    padding-right: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-44 {
    margin-right: 44px;
  }
  .sp-pr-44 {
    padding-right: 44px;
  }
}

.mb-44 {
  margin-bottom: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

@media screen and (min-width: 768px) {
  .pc-mb-44 {
    margin-bottom: 44px;
  }
  .pc-pb-44 {
    padding-bottom: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb44 {
    margin-bottom: 44px;
  }
  .sp-pb44 {
    padding-bottom: 44px;
  }
}

.ml-44 {
  margin-left: 44px;
}

.pl-44 {
  padding-left: 44px;
}

@media screen and (min-width: 768px) {
  .pc-ml-44 {
    margin-left: 44px;
  }
  .pc-pl-44 {
    padding-left: 44px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml44 {
    margin-left: 44px;
  }
  .sp-pl44 {
    padding-left: 44px;
  }
}

.mt-48 {
  margin-top: 48px;
}

.pt-48 {
  padding-top: 48px;
}

@media screen and (min-width: 768px) {
  .pc-mt-48 {
    margin-top: 48px;
  }
  .pc-pt-48 {
    padding-top: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-48 {
    margin-top: 48px;
  }
  .sp-pt-48 {
    padding-top: 48px;
  }
}

.mr-48 {
  margin-right: 48px;
}

.pr-48 {
  padding-right: 48px;
}

@media screen and (min-width: 768px) {
  .pc-mr-48 {
    margin-right: 48px;
  }
  .pc-pr-48 {
    padding-right: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-48 {
    margin-right: 48px;
  }
  .sp-pr-48 {
    padding-right: 48px;
  }
}

.mb-48 {
  margin-bottom: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

@media screen and (min-width: 768px) {
  .pc-mb-48 {
    margin-bottom: 48px;
  }
  .pc-pb-48 {
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb48 {
    margin-bottom: 48px;
  }
  .sp-pb48 {
    padding-bottom: 48px;
  }
}

.ml-48 {
  margin-left: 48px;
}

.pl-48 {
  padding-left: 48px;
}

@media screen and (min-width: 768px) {
  .pc-ml-48 {
    margin-left: 48px;
  }
  .pc-pl-48 {
    padding-left: 48px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml48 {
    margin-left: 48px;
  }
  .sp-pl48 {
    padding-left: 48px;
  }
}

.mt-52 {
  margin-top: 52px;
}

.pt-52 {
  padding-top: 52px;
}

@media screen and (min-width: 768px) {
  .pc-mt-52 {
    margin-top: 52px;
  }
  .pc-pt-52 {
    padding-top: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-52 {
    margin-top: 52px;
  }
  .sp-pt-52 {
    padding-top: 52px;
  }
}

.mr-52 {
  margin-right: 52px;
}

.pr-52 {
  padding-right: 52px;
}

@media screen and (min-width: 768px) {
  .pc-mr-52 {
    margin-right: 52px;
  }
  .pc-pr-52 {
    padding-right: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-52 {
    margin-right: 52px;
  }
  .sp-pr-52 {
    padding-right: 52px;
  }
}

.mb-52 {
  margin-bottom: 52px;
}

.pb-52 {
  padding-bottom: 52px;
}

@media screen and (min-width: 768px) {
  .pc-mb-52 {
    margin-bottom: 52px;
  }
  .pc-pb-52 {
    padding-bottom: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb52 {
    margin-bottom: 52px;
  }
  .sp-pb52 {
    padding-bottom: 52px;
  }
}

.ml-52 {
  margin-left: 52px;
}

.pl-52 {
  padding-left: 52px;
}

@media screen and (min-width: 768px) {
  .pc-ml-52 {
    margin-left: 52px;
  }
  .pc-pl-52 {
    padding-left: 52px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml52 {
    margin-left: 52px;
  }
  .sp-pl52 {
    padding-left: 52px;
  }
}

.mt-56 {
  margin-top: 56px;
}

.pt-56 {
  padding-top: 56px;
}

@media screen and (min-width: 768px) {
  .pc-mt-56 {
    margin-top: 56px;
  }
  .pc-pt-56 {
    padding-top: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-56 {
    margin-top: 56px;
  }
  .sp-pt-56 {
    padding-top: 56px;
  }
}

.mr-56 {
  margin-right: 56px;
}

.pr-56 {
  padding-right: 56px;
}

@media screen and (min-width: 768px) {
  .pc-mr-56 {
    margin-right: 56px;
  }
  .pc-pr-56 {
    padding-right: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-56 {
    margin-right: 56px;
  }
  .sp-pr-56 {
    padding-right: 56px;
  }
}

.mb-56 {
  margin-bottom: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

@media screen and (min-width: 768px) {
  .pc-mb-56 {
    margin-bottom: 56px;
  }
  .pc-pb-56 {
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb56 {
    margin-bottom: 56px;
  }
  .sp-pb56 {
    padding-bottom: 56px;
  }
}

.ml-56 {
  margin-left: 56px;
}

.pl-56 {
  padding-left: 56px;
}

@media screen and (min-width: 768px) {
  .pc-ml-56 {
    margin-left: 56px;
  }
  .pc-pl-56 {
    padding-left: 56px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml56 {
    margin-left: 56px;
  }
  .sp-pl56 {
    padding-left: 56px;
  }
}

.mt-60 {
  margin-top: 60px;
}

.pt-60 {
  padding-top: 60px;
}

@media screen and (min-width: 768px) {
  .pc-mt-60 {
    margin-top: 60px;
  }
  .pc-pt-60 {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-60 {
    margin-top: 60px;
  }
  .sp-pt-60 {
    padding-top: 60px;
  }
}

.mr-60 {
  margin-right: 60px;
}

.pr-60 {
  padding-right: 60px;
}

@media screen and (min-width: 768px) {
  .pc-mr-60 {
    margin-right: 60px;
  }
  .pc-pr-60 {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-60 {
    margin-right: 60px;
  }
  .sp-pr-60 {
    padding-right: 60px;
  }
}

.mb-60 {
  margin-bottom: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .pc-mb-60 {
    margin-bottom: 60px;
  }
  .pc-pb-60 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb60 {
    margin-bottom: 60px;
  }
  .sp-pb60 {
    padding-bottom: 60px;
  }
}

.ml-60 {
  margin-left: 60px;
}

.pl-60 {
  padding-left: 60px;
}

@media screen and (min-width: 768px) {
  .pc-ml-60 {
    margin-left: 60px;
  }
  .pc-pl-60 {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml60 {
    margin-left: 60px;
  }
  .sp-pl60 {
    padding-left: 60px;
  }
}

.mt-64 {
  margin-top: 64px;
}

.pt-64 {
  padding-top: 64px;
}

@media screen and (min-width: 768px) {
  .pc-mt-64 {
    margin-top: 64px;
  }
  .pc-pt-64 {
    padding-top: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-64 {
    margin-top: 64px;
  }
  .sp-pt-64 {
    padding-top: 64px;
  }
}

.mr-64 {
  margin-right: 64px;
}

.pr-64 {
  padding-right: 64px;
}

@media screen and (min-width: 768px) {
  .pc-mr-64 {
    margin-right: 64px;
  }
  .pc-pr-64 {
    padding-right: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-64 {
    margin-right: 64px;
  }
  .sp-pr-64 {
    padding-right: 64px;
  }
}

.mb-64 {
  margin-bottom: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

@media screen and (min-width: 768px) {
  .pc-mb-64 {
    margin-bottom: 64px;
  }
  .pc-pb-64 {
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb64 {
    margin-bottom: 64px;
  }
  .sp-pb64 {
    padding-bottom: 64px;
  }
}

.ml-64 {
  margin-left: 64px;
}

.pl-64 {
  padding-left: 64px;
}

@media screen and (min-width: 768px) {
  .pc-ml-64 {
    margin-left: 64px;
  }
  .pc-pl-64 {
    padding-left: 64px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml64 {
    margin-left: 64px;
  }
  .sp-pl64 {
    padding-left: 64px;
  }
}

.mt-68 {
  margin-top: 68px;
}

.pt-68 {
  padding-top: 68px;
}

@media screen and (min-width: 768px) {
  .pc-mt-68 {
    margin-top: 68px;
  }
  .pc-pt-68 {
    padding-top: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-68 {
    margin-top: 68px;
  }
  .sp-pt-68 {
    padding-top: 68px;
  }
}

.mr-68 {
  margin-right: 68px;
}

.pr-68 {
  padding-right: 68px;
}

@media screen and (min-width: 768px) {
  .pc-mr-68 {
    margin-right: 68px;
  }
  .pc-pr-68 {
    padding-right: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-68 {
    margin-right: 68px;
  }
  .sp-pr-68 {
    padding-right: 68px;
  }
}

.mb-68 {
  margin-bottom: 68px;
}

.pb-68 {
  padding-bottom: 68px;
}

@media screen and (min-width: 768px) {
  .pc-mb-68 {
    margin-bottom: 68px;
  }
  .pc-pb-68 {
    padding-bottom: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb68 {
    margin-bottom: 68px;
  }
  .sp-pb68 {
    padding-bottom: 68px;
  }
}

.ml-68 {
  margin-left: 68px;
}

.pl-68 {
  padding-left: 68px;
}

@media screen and (min-width: 768px) {
  .pc-ml-68 {
    margin-left: 68px;
  }
  .pc-pl-68 {
    padding-left: 68px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml68 {
    margin-left: 68px;
  }
  .sp-pl68 {
    padding-left: 68px;
  }
}

.mt-72 {
  margin-top: 72px;
}

.pt-72 {
  padding-top: 72px;
}

@media screen and (min-width: 768px) {
  .pc-mt-72 {
    margin-top: 72px;
  }
  .pc-pt-72 {
    padding-top: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-72 {
    margin-top: 72px;
  }
  .sp-pt-72 {
    padding-top: 72px;
  }
}

.mr-72 {
  margin-right: 72px;
}

.pr-72 {
  padding-right: 72px;
}

@media screen and (min-width: 768px) {
  .pc-mr-72 {
    margin-right: 72px;
  }
  .pc-pr-72 {
    padding-right: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-72 {
    margin-right: 72px;
  }
  .sp-pr-72 {
    padding-right: 72px;
  }
}

.mb-72 {
  margin-bottom: 72px;
}

.pb-72 {
  padding-bottom: 72px;
}

@media screen and (min-width: 768px) {
  .pc-mb-72 {
    margin-bottom: 72px;
  }
  .pc-pb-72 {
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb72 {
    margin-bottom: 72px;
  }
  .sp-pb72 {
    padding-bottom: 72px;
  }
}

.ml-72 {
  margin-left: 72px;
}

.pl-72 {
  padding-left: 72px;
}

@media screen and (min-width: 768px) {
  .pc-ml-72 {
    margin-left: 72px;
  }
  .pc-pl-72 {
    padding-left: 72px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml72 {
    margin-left: 72px;
  }
  .sp-pl72 {
    padding-left: 72px;
  }
}

.mt-76 {
  margin-top: 76px;
}

.pt-76 {
  padding-top: 76px;
}

@media screen and (min-width: 768px) {
  .pc-mt-76 {
    margin-top: 76px;
  }
  .pc-pt-76 {
    padding-top: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-76 {
    margin-top: 76px;
  }
  .sp-pt-76 {
    padding-top: 76px;
  }
}

.mr-76 {
  margin-right: 76px;
}

.pr-76 {
  padding-right: 76px;
}

@media screen and (min-width: 768px) {
  .pc-mr-76 {
    margin-right: 76px;
  }
  .pc-pr-76 {
    padding-right: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-76 {
    margin-right: 76px;
  }
  .sp-pr-76 {
    padding-right: 76px;
  }
}

.mb-76 {
  margin-bottom: 76px;
}

.pb-76 {
  padding-bottom: 76px;
}

@media screen and (min-width: 768px) {
  .pc-mb-76 {
    margin-bottom: 76px;
  }
  .pc-pb-76 {
    padding-bottom: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb76 {
    margin-bottom: 76px;
  }
  .sp-pb76 {
    padding-bottom: 76px;
  }
}

.ml-76 {
  margin-left: 76px;
}

.pl-76 {
  padding-left: 76px;
}

@media screen and (min-width: 768px) {
  .pc-ml-76 {
    margin-left: 76px;
  }
  .pc-pl-76 {
    padding-left: 76px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml76 {
    margin-left: 76px;
  }
  .sp-pl76 {
    padding-left: 76px;
  }
}

.mt-80 {
  margin-top: 80px;
}

.pt-80 {
  padding-top: 80px;
}

@media screen and (min-width: 768px) {
  .pc-mt-80 {
    margin-top: 80px;
  }
  .pc-pt-80 {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-80 {
    margin-top: 80px;
  }
  .sp-pt-80 {
    padding-top: 80px;
  }
}

.mr-80 {
  margin-right: 80px;
}

.pr-80 {
  padding-right: 80px;
}

@media screen and (min-width: 768px) {
  .pc-mr-80 {
    margin-right: 80px;
  }
  .pc-pr-80 {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-80 {
    margin-right: 80px;
  }
  .sp-pr-80 {
    padding-right: 80px;
  }
}

.mb-80 {
  margin-bottom: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .pc-mb-80 {
    margin-bottom: 80px;
  }
  .pc-pb-80 {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb80 {
    margin-bottom: 80px;
  }
  .sp-pb80 {
    padding-bottom: 80px;
  }
}

.ml-80 {
  margin-left: 80px;
}

.pl-80 {
  padding-left: 80px;
}

@media screen and (min-width: 768px) {
  .pc-ml-80 {
    margin-left: 80px;
  }
  .pc-pl-80 {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml80 {
    margin-left: 80px;
  }
  .sp-pl80 {
    padding-left: 80px;
  }
}

.mt-84 {
  margin-top: 84px;
}

.pt-84 {
  padding-top: 84px;
}

@media screen and (min-width: 768px) {
  .pc-mt-84 {
    margin-top: 84px;
  }
  .pc-pt-84 {
    padding-top: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-84 {
    margin-top: 84px;
  }
  .sp-pt-84 {
    padding-top: 84px;
  }
}

.mr-84 {
  margin-right: 84px;
}

.pr-84 {
  padding-right: 84px;
}

@media screen and (min-width: 768px) {
  .pc-mr-84 {
    margin-right: 84px;
  }
  .pc-pr-84 {
    padding-right: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-84 {
    margin-right: 84px;
  }
  .sp-pr-84 {
    padding-right: 84px;
  }
}

.mb-84 {
  margin-bottom: 84px;
}

.pb-84 {
  padding-bottom: 84px;
}

@media screen and (min-width: 768px) {
  .pc-mb-84 {
    margin-bottom: 84px;
  }
  .pc-pb-84 {
    padding-bottom: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb84 {
    margin-bottom: 84px;
  }
  .sp-pb84 {
    padding-bottom: 84px;
  }
}

.ml-84 {
  margin-left: 84px;
}

.pl-84 {
  padding-left: 84px;
}

@media screen and (min-width: 768px) {
  .pc-ml-84 {
    margin-left: 84px;
  }
  .pc-pl-84 {
    padding-left: 84px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml84 {
    margin-left: 84px;
  }
  .sp-pl84 {
    padding-left: 84px;
  }
}

.mt-88 {
  margin-top: 88px;
}

.pt-88 {
  padding-top: 88px;
}

@media screen and (min-width: 768px) {
  .pc-mt-88 {
    margin-top: 88px;
  }
  .pc-pt-88 {
    padding-top: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-88 {
    margin-top: 88px;
  }
  .sp-pt-88 {
    padding-top: 88px;
  }
}

.mr-88 {
  margin-right: 88px;
}

.pr-88 {
  padding-right: 88px;
}

@media screen and (min-width: 768px) {
  .pc-mr-88 {
    margin-right: 88px;
  }
  .pc-pr-88 {
    padding-right: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-88 {
    margin-right: 88px;
  }
  .sp-pr-88 {
    padding-right: 88px;
  }
}

.mb-88 {
  margin-bottom: 88px;
}

.pb-88 {
  padding-bottom: 88px;
}

@media screen and (min-width: 768px) {
  .pc-mb-88 {
    margin-bottom: 88px;
  }
  .pc-pb-88 {
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb88 {
    margin-bottom: 88px;
  }
  .sp-pb88 {
    padding-bottom: 88px;
  }
}

.ml-88 {
  margin-left: 88px;
}

.pl-88 {
  padding-left: 88px;
}

@media screen and (min-width: 768px) {
  .pc-ml-88 {
    margin-left: 88px;
  }
  .pc-pl-88 {
    padding-left: 88px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml88 {
    margin-left: 88px;
  }
  .sp-pl88 {
    padding-left: 88px;
  }
}

.mt-92 {
  margin-top: 92px;
}

.pt-92 {
  padding-top: 92px;
}

@media screen and (min-width: 768px) {
  .pc-mt-92 {
    margin-top: 92px;
  }
  .pc-pt-92 {
    padding-top: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-92 {
    margin-top: 92px;
  }
  .sp-pt-92 {
    padding-top: 92px;
  }
}

.mr-92 {
  margin-right: 92px;
}

.pr-92 {
  padding-right: 92px;
}

@media screen and (min-width: 768px) {
  .pc-mr-92 {
    margin-right: 92px;
  }
  .pc-pr-92 {
    padding-right: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-92 {
    margin-right: 92px;
  }
  .sp-pr-92 {
    padding-right: 92px;
  }
}

.mb-92 {
  margin-bottom: 92px;
}

.pb-92 {
  padding-bottom: 92px;
}

@media screen and (min-width: 768px) {
  .pc-mb-92 {
    margin-bottom: 92px;
  }
  .pc-pb-92 {
    padding-bottom: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb92 {
    margin-bottom: 92px;
  }
  .sp-pb92 {
    padding-bottom: 92px;
  }
}

.ml-92 {
  margin-left: 92px;
}

.pl-92 {
  padding-left: 92px;
}

@media screen and (min-width: 768px) {
  .pc-ml-92 {
    margin-left: 92px;
  }
  .pc-pl-92 {
    padding-left: 92px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml92 {
    margin-left: 92px;
  }
  .sp-pl92 {
    padding-left: 92px;
  }
}

.mt-96 {
  margin-top: 96px;
}

.pt-96 {
  padding-top: 96px;
}

@media screen and (min-width: 768px) {
  .pc-mt-96 {
    margin-top: 96px;
  }
  .pc-pt-96 {
    padding-top: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-96 {
    margin-top: 96px;
  }
  .sp-pt-96 {
    padding-top: 96px;
  }
}

.mr-96 {
  margin-right: 96px;
}

.pr-96 {
  padding-right: 96px;
}

@media screen and (min-width: 768px) {
  .pc-mr-96 {
    margin-right: 96px;
  }
  .pc-pr-96 {
    padding-right: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-96 {
    margin-right: 96px;
  }
  .sp-pr-96 {
    padding-right: 96px;
  }
}

.mb-96 {
  margin-bottom: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

@media screen and (min-width: 768px) {
  .pc-mb-96 {
    margin-bottom: 96px;
  }
  .pc-pb-96 {
    padding-bottom: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb96 {
    margin-bottom: 96px;
  }
  .sp-pb96 {
    padding-bottom: 96px;
  }
}

.ml-96 {
  margin-left: 96px;
}

.pl-96 {
  padding-left: 96px;
}

@media screen and (min-width: 768px) {
  .pc-ml-96 {
    margin-left: 96px;
  }
  .pc-pl-96 {
    padding-left: 96px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml96 {
    margin-left: 96px;
  }
  .sp-pl96 {
    padding-left: 96px;
  }
}

.mt-100 {
  margin-top: 100px;
}

.pt-100 {
  padding-top: 100px;
}

@media screen and (min-width: 768px) {
  .pc-mt-100 {
    margin-top: 100px;
  }
  .pc-pt-100 {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mt-100 {
    margin-top: 100px;
  }
  .sp-pt-100 {
    padding-top: 100px;
  }
}

.mr-100 {
  margin-right: 100px;
}

.pr-100 {
  padding-right: 100px;
}

@media screen and (min-width: 768px) {
  .pc-mr-100 {
    margin-right: 100px;
  }
  .pc-pr-100 {
    padding-right: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mr-100 {
    margin-right: 100px;
  }
  .sp-pr-100 {
    padding-right: 100px;
  }
}

.mb-100 {
  margin-bottom: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

@media screen and (min-width: 768px) {
  .pc-mb-100 {
    margin-bottom: 100px;
  }
  .pc-pb-100 {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-mb100 {
    margin-bottom: 100px;
  }
  .sp-pb100 {
    padding-bottom: 100px;
  }
}

.ml-100 {
  margin-left: 100px;
}

.pl-100 {
  padding-left: 100px;
}

@media screen and (min-width: 768px) {
  .pc-ml-100 {
    margin-left: 100px;
  }
  .pc-pl-100 {
    padding-left: 100px;
  }
}

@media screen and (max-width: 767px) {
  .sp-ml100 {
    margin-left: 100px;
  }
  .sp-pl100 {
    padding-left: 100px;
  }
}

.d-inline_block {
  display: inline-block;
}

/*----------------------------------------------------------------【見出し】*/
h2 {
  padding: 0 16px;
  color: #3d270f;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.6;
}

h2.more {
  padding: 0;
}

#contents > h2,
#contents #tab1 > h2,
#contents #tab2 > h2 {
  margin-top: 24px;
}

.contents_m > h2.comp {
  margin-top: 24px;
}

/*----------------------------------------------------------------【外枠設定】*/
#wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

/* ================================================================================ */
/*                                                                                  */
/*      ヘッダーデザイン                                                            */
/*                                                                                  */
/* ================================================================================ */
header {
  padding: 12px 8px;
  background-color: #FFFFFF;
}

header #logo {
  float: left;
  height: 32px;
}

header #logo img {
  width: 180px;
}

header .entry, header .present {
  width: 40px;
  height: auto;
  float: right;
  margin-right: 10px;
  text-align: center;
  font-size: 1rem;
  text-decoration: none;
}

header .entry img, header .present img {
  width: 100%;
  max-width: 24px;
  margin-bottom: 3px;
}

header .entry {
  color: #FF7E41;
}

header .present {
  color: #3D270F;
}

header .menu {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 40px;
  height: 40px;
  text-decoration: none;
}

header .menuBtn {
  cursor: pointer;
}

header .menuBtn .border1,
header .menuBtn .border2,
header .menuBtn .border3 {
  fill: #3D270F;
  transition: .3s all ease;
  transform-origin: 24px 24px;
}

header .menuBtn .changeBorder1 {
  transform: translate(25%, 0%) rotate(45deg);
}

header .menuBtn .changeBorder2 {
  opacity: 0;
}

header .menuBtn .changeBorder3 {
  transform: translate(-40%, 25%) rotate(-45deg);
}

header .menu p {
  color: #3D270F;
  font-size: 1rem;
  line-height: 1;
}

header .new_message {
  position: absolute;
  right: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 80%;
  padding: 2px 4px;
  display: inline-block;
  background-color: #f03;
}

header .new_message span {
  display: none;
}

/* ================================================================================ */
/*                                                                                  */
/*      メニューデザイン                                                            */
/*                                                                                  */
/* ================================================================================ */
.l-offset {
  background-color: #F2F2F2;
  box-shadow: 0px 2px 4px rgba(54, 59, 64, 0.2);
  padding: 24px 16px;
  display: none;
}

.offsetClose {
  margin: 20px 0 0;
}

.offsetClose__btn {
  position: relative;
  display: block;
  padding: 14px 16px 14px 24px;
  background-color: #FFFFFF;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 6px;
  color: #828282;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
}

.offsetClose__btn svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

/* メニューパネル
----------------------------------------------- */
.myMenuPanel {
  margin: 0 0 10px;
}

.myMenuPanel__title {
  background-color: #555;
  background-image: url(../../img/sp/icon/user02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 8px;
  background-size: 24px;
  color: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0 10px 0 34px;
  font-size: 1.2rem;
}

.myMenuPanel__title a {
  color: #fff;
}

.myMenuPanel__title .fa {
  font-size: 20px;
  vertical-align: middle;
  margin: 0 5px 0 0;
}

.myMenuPanel__title__name {
  color: #ff7e41;
  margin: 0 5px;
}

.myMenuList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
}

.myMenuList__item {
  width: 31%;
  margin-top: 8px;
}

.myMenuList__item + .myMenuList__item {
  margin-left: 8px;
}

.myMenuList__item > a {
  display: block;
  padding: 8px;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.4);
  text-decoration: none;
}

.myMenuList__item__icon {
  position: relative;
  display: block;
  height: 42px;
  width: 42px;
  margin: 4px auto 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.myMenuList__item__icon__cnt {
  position: absolute;
  top: 0;
  right: -4px;
  width: 16px;
  height: 16px;
  background: #FF7E41;
  color: #FFFFFF;
  font-size: 1rem;
  border-radius: 50%;
  padding: 1px;
  text-align: center;
}

.myMenuList__item__name {
  display: table;
  width: 100%;
}

.myMenuList__item__name > span {
  display: table-cell;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  color: #3D270F;
  height: 3em;
  line-height: 1.4em;
  vertical-align: middle;
}

.myMenuList__item__icon--message {
  background-image: url(../../img/sp/icon/mail.svg);
}

.myMenuList__item__icon--entry {
  background-image: url(../../img/sp/icon/time.svg);
}

.myMenuList__item__icon--resume {
  background-image: url(../../img/sp/icon/entry02.svg);
}

.myMenuList__item__icon--clip {
  background-image: url(../../img/sp/icon/note.svg);
}

.myMenuList__item__icon--edit {
  background-image: url(../../img/sp/icon/user.svg);
}

.myMenuList__item__icon--search {
  background-image: url(../../img/sp/mymenulist_search.png);
}

/* myMenuLinks */
.myMenuLinks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
}

.myMenuLinks_head {
  flex-wrap: nowrap;
  padding: 0 35px;
}

.myMenuLinks_body {
  width: 100%;
  margin: 16px 0;
  padding: 16px;
  background-color: #FFFFFF;
  border: 4px solid #FF7E41;
  border-radius: 4px;
}

.myMenuLinks_body h2 {
  margin-bottom: 14px;
  padding: 0;
  border: none;
  background: none;
  color: #3D270F;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
}

.myMenuLinks_body h2 svg {
  margin: 0 2px -4px 0;
}

.myMenuLinks__item {
  display: flex;
  width: calc(100% / 2 - 5px);
}

.myMenuLinks__item-w100 {
  width: 100%;
  margin-bottom: 16px;
}

.myMenuLinks__item > a {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 12px 48px 12px 16px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.4);
  text-decoration: none;
}

.myMenuLinks__item > a::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 16px;
  background-image: url(../../img/sp/icon/arrow/right-circle.svg);
  transform: translateY(-50%);
}

.myMenuLinks__item--register + .myMenuLinks__item--login {
  margin-left: 16px;
}

.myMenuLinks__item--register a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
  background: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
}

.myMenuLinks__item--register a::before {
  content: none;
}

.myMenuLinks__item--register a svg {
  margin-right: 4px;
}

.myMenuLinks__item--login a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FF7E41;
  font-size: 1.6rem;
  font-weight: 700;
}

.myMenuLinks__item--login a::before {
  content: none;
}

.myMenuLinks__item--login a svg {
  margin-right: 4px;
}

.myMenuLinks__item--fresh a {
  background: #ddd;
  border: 1px solid #888;
}

.myMenuLinks__item--fresh a .fa {
  margin: 0 10px 0 0;
  font-size: 15px;
  vertical-align: top;
}

.myMenuLinks__item--fresh.active a {
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
}

.myMenuLinks__item--mid a {
  background: #ddd;
  border: 1px solid #888;
}

.myMenuLinks__item--mid.active a {
  background-image: linear-gradient(to right, #fff 0%, #fff 100%);
}

.myMenuLinks__item--mid.active a .fa {
  margin: 0 10px 0 0;
  font-size: 15px;
  vertical-align: top;
}

.pageslide {
  /* These styles MUST be included. Do not change. */
  display: none;
  position: absolute;
  top: 64px;
  z-index: 999999;
  /* Specify the width of your pageslide here */
  width: 100%;
  /* These styles are optional, and describe how the pageslide will look */
  background-color: #F2F2F2;
  color: #2382C7;
  font-size: 1.6rem;
  font-weight: 700;
}

.myMenuList__2colBody {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.myMenuList__2colBody .myMenuList__item {
  width: calc(50% - 4px);
}

/* ================================================================================ */
/*                                                                                  */
/*      フッターデザイン                                                            */
/*                                                                                  */
/* ================================================================================ */
footer {
  margin-top: 70px;
  padding-top: 45px;
  position: relative;
  background-color: #FFFFFF;
  background-image: url("../../img/sp/common/footer_bg.png");
  background-repeat: repeat-x;
  background-size: 57px;
}

footer .foot_inline .foot_menu {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

footer #pagetop {
  position: absolute;
  right: 5px;
  bottom: 282px;
}

footer .st-ftMenu {
  padding: 0 16px 16px 16px;
}

footer .st-ftMenu li {
  display: flex;
  align-items: center;
  width: 50%;
  height: 48px;
}

footer .st-ftMenu a {
  color: #3D270F;
  font-size: 1.6rem;
  text-decoration: none;
}

footer .pcBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
  height: 40px;
  margin: 0 auto 16px;
  background: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 700;
}

footer .ft-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-top: 1px solid #DBD5CC;
}

footer .ft-logo img {
  object-fit: contain;
  width: 160px;
  height: 40px;
}

/* ================================================================================ */
/*                                                                                  */
/*      コンテンツデザイン                                                          */
/*                                                                                  */
/* ================================================================================ */
/*----------------------------------------------------------------【汎用デザイン】*/
#contents {
  background-color: #fffdf4;
}

#contents .head-description {
  margin: 24px 0;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
}

.contents_box {
  background: #fff;
  margin: 0 0 20px;
}

.main {
  margin: 0 0 24px;
  padding: 16px 16px 0;
}

.main.search {
  padding: 0 16px 0;
}

.main.info {
  padding: 16px 20px;
}

.main table {
  width: 100%;
  line-height: 1.5em;
  margin-bottom: 20px;
  font-size: 10px;
}

.main table caption {
  background: #555;
  color: #fff;
  font-size: 10px;
  padding: 4px 5px;
}

.main table th {
  background: #f4f4f4;
  border: 1px solid #ccc;
  padding: 4px 5px;
}

.main table td {
  background: #fff;
  border: 1px solid #ccc;
  padding: 4px 5px;
}

.pageInformation {
  line-height: 1.6em;
  margin: .6em 0;
  font-size: 12px;
}

/*--------------------------------------------------------------求人検索パネル(トップ)*/
.mainVisual__img--sp {
  width: 100%;
}

.mainVisual__img--sp img {
  width: 100%;
}

.jobSearchSelect {
  margin: 24px 0 0;
  padding: 0 16px;
}

.jobSearchSelect__switch {
  display: flex;
  margin: 0 -2px;
}

.jobSearchSelect__switch__title {
  padding: 0 2px;
  width: 50%;
}

.jobSearchSelect__switch__title a {
  display: block;
  padding: 10px 10px 8px;
  font-size: 11px;
  font-weight: bold;
  background: #d2d2d2;
  color: #666;
  text-decoration: none;
}

.jobSearchSelect__switch__title a .fa {
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.jobSearchSelect__switch__title--active a {
  background: #666;
  color: #fff;
}

.jobSearchSelect__body {
  padding: 16px;
  background: #FFFFFF;
  border: 4px solid #FF8951;
  border-radius: 4px;
}

.jobSearchF {
  display: flex;
  margin: 0 -5px;
}

.jobSearchF__title {
  width: 100%;
  margin: 0 0 24px 0;
  padding: 0;
  background: none;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

.jobSearchF__title > a {
  display: block;
  text-decoration: none;
  border: 1px solid #666;
  border-radius: 2px;
  background-image: linear-gradient(#fff 20%, #efefef);
  color: #333;
  padding: 10px;
}

.jobSearchF__title__icon {
  display: block;
  margin: 0 auto 5px;
  height: 40px;
}

.jobSearchF__title__icon .fa {
  line-height: 40px;
  font-size: 30px;
}

.jobSearchF__title__name {
  color: #333;
  font-size: 12px;
}

.jobSearchC {
  margin: 10px 0;
}

.jobSearchC__now p {
  padding: 3px;
  margin: 0;
  font-size: 11px;
  color: #666;
}

.jobsearchc__nowp:empty {
  display: none;
}

.jobSearchFW__ {
  display: block;
}

.jobSearchFW__text {
  flex-grow: 1;
  padding: 0 5px 0 0;
}

.jobSearchFW__text > input[type="text"] {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
  width: 100%;
}

.jobSearchFW__submit > input[type="submit"] {
  appearance: none;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  height: 30px;
  padding: 0 1em;
  border-radius: 3px;
  background: #333;
  border: 1px solid #333;
  color: #fff;
  cursor: pointer;
}

.cmn-stylishSelect {
  position: relative;
}

.cmn-stylishSelect + .cmn-stylishSelect {
  margin-top: 16px;
}

.cmn-stylishSelect select {
  width: 100%;
  height: 40px;
  appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 8px 32px;
  font-size: 1.6rem;
  color: #828282;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
}

.cmn-stylishSelect .ic-before {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.cmn-stylishSelect .ic-arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.easySearchForm__submit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px auto 0;
  padding: 0;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.easySearchForm__submit::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 30px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/icon/search02.svg);
  transform: translateY(-50%);
}

.cmn-stylishBtn {
  appearance: none;
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 44px 0 52px;
  background-color: #FF7E41;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

/* add トップ search Tab
----------------------------------------------- */
.tp-search_TabBtn {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.tp-search_TabBtn .tab-recommend,
.tp-search_TabBtn .tab-new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 64px;
  background-color: #F2F2F2;
  border-radius: 4px 4px 0 0;
  color: #828282;
  font-size: 1.6rem;
  font-weight: 700;
}

.tp-search_TabBtn .tab-recommend.isActive,
.tp-search_TabBtn .tab-new.isActive {
  background-color: #FF7E41;
  color: #FFFFFF;
}

.tp-search_TabBtn .tab-recommend svg,
.tp-search_TabBtn .tab-new svg {
  fill: #828282;
  margin-right: 8px;
}

.tp-search_TabBtn .tab-recommend.isActive svg,
.tp-search_TabBtn .tab-new.isActive svg {
  fill: #FFFFFF;
}

.tp-search_TabContent {
  padding: 24px 0 24px 24px;
  background-color: #FFD0B9;
}

.tp-search_TabContent > section {
  display: none;
}

.tp-search_TabContent > section.isActive {
  display: block;
}

.tp-search_Tab-contents-recommend .item_list,
.tp-search_Tab-contents-new .item_list {
  display: flex;
  overflow-x: scroll;
  padding: 8px 0;
}

.moreBtn {
  margin-top: 24px;
  margin-right: 24px;
}

.moreBtn a {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 14px 8px 14px 0;
  background-color: #FF7E41;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #fff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.moreBtn a svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* add トップ tp-special
----------------------------------------------- */
.tp-special {
  position: relative;
  margin-top: 24px;
  padding: 16px;
  background-color: #96C6E8;
}

.tp-special .tp-special_heading {
  margin-left: 70px;
}

.tp-special .tp-special_heading h2 {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding: 0 0 0 16px;
  color: #ffffff;
  background-color: #2382C7;
  border-top: none;
  border-bottom: none;
  border-radius: 16px;
  font-size: 1.6rem;
  text-align: center;
}

.tp-special .tp-special_heading h2::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 97%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 19px 16px 0;
  border-color: transparent #2382C7 transparent transparent;
  transform: translateY(-50%);
}

.tp-special .tp-special_heading h2 span {
  margin-top: -4px;
  margin-right: 16px;
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
}

.tp-special > img {
  position: absolute;
  top: 0;
  left: 0;
}

.tp-special .tp-special_item {
  position: relative;
}

.tp-special .tp-special_item + .tp-special_item {
  margin-top: 4px;
}

.tp-special .tp-special_item a {
  display: flex;
  align-items: center;
  padding: 12px 48px 12px 16px;
  color: #2382C7;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.1);
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
}

.tp-special .tp-special_item a:hover {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.2), rgba(150, 198, 232, 0.2)), #FFFFFF;
}

.tp-special .tp-special_item svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

/* add top インタビュー
----------------------------------------------- */
#contents .contents_box.new.interview {
  margin: 40px 0 0;
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
  border: none;
}

#contents .contents_box.new.interview h2.ic-mic {
  position: relative;
  padding: 0;
  border: none;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

#contents .contents_box.new.interview h2.ic-mic svg {
  margin-right: 8px;
}

#contents .contents_box.new.interview .body {
  padding-top: 0px;
}

#contents .contents_box.new.interview .item_list {
  display: block;
  overflow-x: initial;
}

#contents .contents_box.new.interview .item_list article {
  display: block;
  width: 100%;
}

#contents .contents_box.new.interview .item_list article a {
  display: block;
  margin: 0;
  padding: 8px 10px;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, 0.6);
  text-decoration: none;
}

#contents .contents_box.new.interview .item_list article a .flex {
  display: flex;
}

#contents .contents_box.new.interview .item_list article + article {
  margin-top: 8px;
  margin-left: 0;
}

#contents .contents_box.new.interview .item_list article .img {
  width: 70px;
}

#contents .contents_box.new.interview .item_list article .img img {
  object-fit: contain;
  width: 70px;
  height: 50px;
  border: none;
}

#contents .contents_box.new.interview .item_list article .title {
  margin: 8px 0 0;
  padding: 0;
  color: #2382C7;
  font-size: 1.6rem;
  border-left: none;
}

#contents .contents_box.new.interview .item_list article .cname {
  display: block;
  margin: 0;
  color: #000000;
  font-size: 1.2rem;
}

#contents .contents_box.new.interview .item_list article .text h3 {
  width: 100%;
  margin-left: 8px;
  color: #2382C7;
  font-size: 16px;
}

#contents .contents_box.new.interview .body > .more {
  margin-top: 24px;
  padding: 0;
  background: initial;
  text-align: center;
}

#contents .contents_box.new.interview .body > .more a {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 14px 10px 14px 0;
  background-color: #FF7E41;
  color: #ffffff;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

#contents .contents_box.new.interview .body > .more a svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.tp-contentWrap {
  padding: 0 16px;
}

#contents .contents_box.new,
#contents .contents_box.other,
#contents .contents_box.pickup {
  margin: 32px 0 0;
  padding: 12px 0 30px 10px;
  background-color: #FFF0DB;
  border-radius: 4px;
  border: none;
  box-shadow: none;
}

#contents .contents_box.new h2,
#contents .contents_box.other h2,
#contents .contents_box.pickup h2 {
  position: relative;
  padding: 0;
  border: none;
  background: inherit;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

#contents .contents_box.new h2.ic-company svg,
#contents .contents_box.other h2.ic-company svg,
#contents .contents_box.pickup h2.ic-company svg {
  margin-right: 8px;
}

#contents .contents_box.new .item_list,
#contents .contents_box.other .item_list,
#contents .contents_box.pickup .item_list {
  display: flex;
  overflow-x: scroll;
  margin-top: 16px;
  padding: 8px;
}

#contents .contents_box.new_cuser {
  margin-top: 32px;
  padding: 12px 10px 30px;
  background-color: #FFF0DB;
  border-radius: 4px;
  border: none;
}

#contents .contents_box.new_cuser h2 {
  position: relative;
  padding: 0;
  border: none;
  background: inherit;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
}

#contents .contents_box.new_cuser h2.ic-company svg {
  margin-right: 8px;
}

#contents .contents_box.new_cuser .item_list article + article {
  margin-left: 0;
}

#contents .contents_box.new_cuser .item_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#contents .contents_box.new_cuser .item_list .body {
  display: flex;
  flex-wrap: wrap;
}

#contents .contents_box.new_cuser .item_list .price {
  display: none;
}

#contents .contents_box.new_cuser .item_list article {
  width: calc(100% / 2 - 4px);
  margin-top: 16px;
}

#contents .contents_box.new_cuser .item_list article a {
  width: 100%;
  display: block;
}

#contents .contents_box.new_cuser .item_list article img {
  width: 100%;
}

#contents .contents_box.new_cuser .title {
  margin-top: 8px;
  color: #2382C7;
  font-size: 1.2rem;
  line-height: 1.48;
}

/* add トップ pickup
----------------------------------------------- */
.tp-pickup {
  margin-top: 32px;
  background-color: #96C6E8;
  border-radius: 4px;
  border-top: 8px solid #FF7E41;
}

.tp-pickup h2 {
  position: relative;
  padding: 12px 0 12px 16px;
  background: #2382C7;
  color: #ffffff;
  line-height: 1;
  border: none;
  margin-bottom: 0;
  font-size: 1.8rem;
}

.tp-pickup h2::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 151px;
  height: 58px;
  background-image: url(../../img/sp/top/pickup_img.png);
  background-repeat: no-repeat;
  background-size: 151px 58px;
}

.tp-pickup h2 span {
  font-size: 2.2rem;
}

.tp-pickup_items {
  padding: 10px 8px;
}

.tp-pickup_items li + li {
  margin-top: 8px;
}

.tp-pickup_item {
  position: relative;
  display: block;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, 0.6);
  text-decoration: none;
}

.tp-pickup_item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 54px 54px 0;
  border-color: transparent #FFF0DB transparent transparent;
}

.tp-pickup_item::after {
  content: "check!";
  position: absolute;
  top: 14px;
  right: 4px;
  color: #FF7E41;
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  transform: rotate(45deg);
}

.tp-pickup_item_img {
  max-width: 84px;
}

.tp-pickup_item_img img {
  object-fit: contain;
  width: 84px;
}

.tp-pickup_item_txt {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.tp-pickup_item .cate {
  margin-bottom: 4px;
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 700;
}

.tp-pickup_item .cate span {
  font-size: 1.2rem;
  font-weight: 400;
}

.tp-pickup_item h3 {
  margin-top: 4px;
  margin-bottom: 0;
  padding: 0;
  border-left: none;
  color: #2382C7;
  font-size: 1.6rem;
}

.tp-pickup_item p.tp-pickup_item_linkBtn {
  position: relative;
  display: block;
  margin-top: 12px;
  padding: 6px 64px 6px 56px;
  color: #FF7E41;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.tp-pickup_item p.tp-pickup_item_linkBtn:hover {
  background-color: #FFF0DB;
}

.tp-pickup_item_linkBtn svg {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.tp-pickup_item .flex {
  display: flex;
}

/* add tp-read
----------------------------------------------- */
.tp-read {
  margin-top: 32px;
}

.tp-read_heading {
  position: relative;
  display: flex;
}

.tp-read_heading > img {
  position: absolute;
  bottom: 0;
}

.tp-read_heading h2 {
  position: relative;
  width: 100%;
  margin-left: 90px;
  padding: 12px 18px;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 2.2rem;
  line-height: 1.4;
  border: none;
  margin-bottom: 0;
}

.tp-read_heading h2::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14.5px 15px 14.5px 0;
  border-color: transparent #FFD0B9 transparent transparent;
  transform: translateY(-50%);
}

.tp-read_txt {
  margin-top: 24px;
}

.tp-read_txt p {
  color: #3D270F;
  font-size: 1.6rem;
}

.tp-read_txt p + p {
  margin-top: 24px;
}

/*--------------------------------------------------------------求人検索パネル(検索画面)*/
.jobSearchPanel {
  border: 1px solid #a17547;
  margin: 0 0 10px;
}

.jobSearchPanel__title {
  background: #a17547;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  position: relative;
}

.jobSearchPanel__title__toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}

.jobSearchPanel__title__set {
  position: relative;
  color: #fff;
}

.jobSearchPanel__title__set__icon {
  font-size: 14px;
  margin: 0 5px;
}

.jobSearchPanel__title__set__name {
  font-size: 12px;
  font-weight: bold;
}

.jobSearchPanel__title__set__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.jobSearchPanel__body {
  padding: 10px;
  background: #fff;
  display: none;
}

/* 検索フォーム
----------------------------------------------- */
.jobSearchForm {
  width: 100%;
}

.jobSearchForm__hint {
  color: #673a1b;
  font-size: 11px;
  margin: 10px 0 5px;
}

/* フォーム内の条件
----------------------------------------------- */
.searchCondition {
  margin: 0 0 9px;
  padding: 0 0 9px;
  border-bottom: 1px dashed #ccc;
}

.searchCondition__label {
  padding: 6px 0;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.searchCondition__label__more {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
  text-decoration: none;
}

.searchCondition__label .fa {
  color: #333;
  font-size: 18px;
  margin: 0 9px 0 0;
  vertical-align: middle;
}

.searchCondition__label span {
  font-size: 12px;
  font-weight: bold;
}

.searchCondition__setting__row + .searchCondition__setting__row {
  margin: 5px 0 0;
}

.searchCondition--horizontal {
  display: flex;
}

.searchCondition--horizontal .searchCondition__label {
  width: 100px;
}

.searchCondition--horizontal .searchCondition__setting {
  flex-grow: 1;
}

.searchCondition--submit .searchCondition__label {
  display: none;
}

.searchCondition--submit input[type="submit"] {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  height: 4em;
  border-radius: 3px;
  background: #ff9900;
  border: 1px solid #ff9900;
  background-image: linear-gradient(#fbaa31, #ff9900);
  color: #fff;
  cursor: pointer;
}

/* stylishLabel */
.stylishLabel label {
  position: relative;
  display: inline-block;
  margin: 5px 2px 5px 0;
}

.stylishLabel label input[type="checkbox"] {
  display: none;
}

.stylishLabel label input[type="checkbox"] + span {
  display: inline-block;
  padding: 4px 6px 4px 26px;
  position: relative;
  line-height: 1;
  font-size: 12px;
}

.stylishLabel label input[type="checkbox"] + span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
}

.stylishLabel label input[type="checkbox"]:checked + span::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  transform-origin: top left;
  transform: rotateZ(-45deg);
  width: 7px;
  height: 5px;
  border-bottom: 2px solid #ff9900;
  border-left: 2px solid #ff9900;
}

/* stylishSelect */
.stylishSelect {
  position: relative;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 34px;
  box-sizing: border-box;
}

.stylishSelect__btn {
  position: absolute;
  right: -1px;
  top: -1px;
  height: 34px;
  line-height: 34px;
  padding: 0 10px;
  background: #bbb;
  color: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-sizing: border-box;
}

.stylishSelect select {
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  padding: 0 10px;
  font-size: 12px;
  color: #666;
  width: 100%;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
}

/* stylishText */
.stylishText input[type="text"] {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 7px 10px;
  width: 100%;
  box-sizing: border-box;
}

/*----------------------------------------------------------------【フリーワード検索】*/
#contents .free_search {
  background: #eee;
  padding: 4px 0;
  margin: 0 0 10px;
  box-shadow: none;
}

#contents .free_search label {
  display: none;
}

#contents .free_search form {
  display: block;
  position: relative;
  height: 36px;
  overflow: hidden;
}

#contents .free_search form input[type="text"]#free {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  height: 36px;
  width: 100%;
  z-index: 1;
  background: #fff;
  font-size: 14px;
  padding: 8px 5px;
  border: none;
}

#contents .free_search form input[type="submit"].search_button {
  position: absolute;
  right: 0;
  z-index: 2;
  background: #333;
  color: #fff;
  border: none;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 65px;
  appearance: none;
  cursor: pointer;
}

/*----------------------------------------------------------------【求人詳細】*/
#contents h2.job_h2 span.cate {
  padding-right: 3px;
}

#contents h2.job_h2 span.pickup {
  position: absolute;
  top: 0;
  left: 0;
  background: #FF7E41;
  border-radius: 4px 0 0 0;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

#contents .date {
  font-size: 12px;
  text-align: right;
  padding: 5px;
}

#contents #social {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
  padding: 0 10px;
}

#contents #social div + div {
  margin-left: 16px;
}

.sns-hatena {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/sp/common/sns_b.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sns-twitter {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/common/sns_twi.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sns-facebook {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/common/sns_fb.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.sns-line {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url(../../img/common/sns_line.png);
  background-repeat: no-repeat;
  background-size: 42px 42px;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

/* 特徴アイコン
----------------------------------------------- */
#contents .icon {
  padding: 10px;
}

#contents .icon li {
  margin: 0 4px 4px 0;
  display: inline-block;
  font-size: 11px;
  border: 1px solid #bbb;
  padding: 0 3px;
  white-space: nowrap;
}

#contents .icon li a {
  text-decoration: none;
}

/* flick
----------------------------------------------- */
#contents .flick {
  width: 100%;
  max-width: 300px;
  position: relative;
  overflow: auto;
  margin: 8px auto;
}

@media screen and (max-width: 374px) {
  #contents .flick {
    max-width: 248px;
  }
}

#contents .flick ul {
  display: block;
  margin: 0;
  padding: 0;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

#contents .flick ul li {
  float: left;
  padding: 0;
  width: 100%;
  max-width: 300px;
  border: 0;
  line-height: 1.4;
}

@media screen and (max-width: 374px) {
  #contents .flick ul li {
    max-width: 248px;
  }
}

#contents .flick ul li p.img {
  margin: 0 4px;
  text-align: center;
}

#contents .flick ul li p.img img {
  object-fit: contain;
  width: 100%;
  max-width: 300px;
  height: auto;
}

@media screen and (max-width: 374px) {
  #contents .flick ul li p.img img {
    max-width: 248px;
  }
}

#contents .flick ul li p.img span {
  line-height: 148px;
  display: block;
  background-color: #fafafa;
  border: 1px solid #ccc;
}

#contents .flick ul li p.text {
  padding: 0;
  margin: 0 12px;
  font-size: 12px;
}

#contents .navi {
  margin: 0;
}

#contents .navi a {
  position: absolute;
  top: 50%;
  text-indent: -9999px;
  overflow: hidden;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  transform: translateY(-50%);
}

#contents .nextbtn {
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  right: -10px;
  margin-right: 10px;
}

#contents .backbtn {
  background: url(../../img/sp/arrow3.png) no-repeat left;
  background-size: 20px 11px;
  left: -10px;
  margin-left: 10px;
}

#contents .nextbtn.inactive,
#contents .backbtn.inactive {
  opacity: .3;
}

#contents .indicator {
  position: relative;
  overflow: hidden;
  width: 13px;
  height: 5px;
  background: url(../../img/sp/indicater_bg.png) repeat-x left top;
  margin: 10px auto 10px auto;
}

#contents .indicator img {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

/*----------------------------------------------------------------【リストデザイン】*/
/* link_list
----------------------------------------------- */
.link_list ul li {
  border-bottom: 1px solid #ccc;
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  line-height: 1.5;
}

.link_list ul li a {
  display: block;
  padding: 7px 30px 7px 10px;
  text-decoration: none;
  color: #333;
}

.link_list ul li.image {
  background: none;
  text-align: center;
}

/* info_list
----------------------------------------------- */
#contents .info_list li {
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
  line-height: 1.5;
}

#contents .info_list ul li span {
  color: #3d270f;
  font-weight: bold;
}

#contents .info_list ul li p {
  padding: 0 10px;
}

#contents .info_list ul li p.ic-phone {
  font-family: Roboto;
  font-size: 2.2rem;
  font-weight: 700;
}

#contents .info_list ul li p.ic-phone svg {
  margin-right: 4px;
}

/* item_list
----------------------------------------------- */
#contents .item_list article {
  display: flex;
  align-items: stretch;
  width: 208px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(255, 126, 65, 0.6);
}

#contents .item_list article a {
  display: block;
  padding: 16px;
  text-decoration: none;
}

#contents .item_list article a:nth-child(n + 4) {
  display: none;
}

#contents .item_list article img {
  object-fit: contain;
  width: 176px;
  height: 87.5px;
}

#contents .item_list article .cate {
  margin-top: 8px;
  color: #3D270F;
  font-size: 1.2rem;
}

#contents .item_list article h3,
#contents .item_list article .title {
  margin-bottom: 0;
  padding: 0;
  border-left: none;
  color: #2382C7;
  font-size: 1.2rem;
  font-weight: 400;
}

#contents .item_list article .title span {
  display: none;
}

#contents .item_list article + article {
  margin-left: 16px;
}

#contents .item_list h3 {
  border: 0;
  padding: 0;
  margin-bottom: 5px;
}

#contents .item_list .price {
  font-size: 12px;
  color: #ff4574;
}

#contents .item_list .sub {
  font-size: 12px;
}

#contents .item_list .cate {
  font-size: 12px;
}

/* search_item */
#contents .search_item h3 {
  font-weight: bold;
  color: #666;
  font-size: 15px;
}

#contents .search_item h3 span {
  font-size: 12px;
  font-weight: normal;
}

#contents .search_item h3 .pickup {
  background-color: #dd1144;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
  padding: 3px;
  white-space: nowrap;
}

#contents .search_item .sub {
  font-size: 10px;
}

#contents .search_item table {
  width: 100%;
  font-size: 10px;
  clear: both;
  margin: 0;
}

#contents .search_item table th {
  border: 1px solid #ccc;
  padding: 3px;
  width: 58px;
  font-weight: normal;
  background-color: #fafafa;
}

#contents .search_item table td {
  border: 1px solid #ccc;
  padding: 3px 5px;
}

#contents .search_item article {
  width: 100%;
  flex-wrap: wrap;
  margin: 24px 0 0;
  padding: 0;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

#contents .search_item article > a, #contents .search_item article a.list {
  position: relative;
  width: 100%;
  padding: 0 16px;
  color: #3D270F;
  font-size: 1.6rem;
}

#contents .search_item article + article {
  margin-left: 0;
}

#contents .search_item .title {
  padding: 16px 24px 0;
  border-radius: 4px 4px 0px 0px;
}

#contents .search_item .list_pickup.text {
  position: relative;
  margin: 0 -16px;
  padding: 24px 100px 8px 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px 4px 0px 0px;
}

#contents .search_item .list_pickup.text h3,
#contents .search_item .not_pickup.text h3 {
  color: #2382C7;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
}

#contents .search_item .sub {
  color: #3D270F;
  font-size: 1.2rem;
}

#contents .search_item .img {
  margin: 16px auto 0;
  text-align: center;
}

#contents .search_item .img img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

#contents .search_item .not_pickup.text {
  position: relative;
  margin: 0 -16px;
  padding: 24px 100px 8px 16px;
}

#contents .search_item .text {
  margin: 16px 0 0;
  padding: 0 24px 0;
  color: #3D270F;
  font-size: 1.6rem;
}

#contents .search_item .text .pickup {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background: #FF7E41;
  border-radius: 4px 0 0 0;
  color: #fff;
  padding: 2px 10px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

#contents .search_item .list_pickup.text .pickup {
  left: 66px;
  border-radius: 0;
}

#contents .search_item .list_pickup::before {
  content: "PICKUP";
  position: absolute;
  top: 0;
  left: 0;
  background: #2382C7;
  border-radius: 4px 0px 0px 0px;
  color: #fff;
  margin-right: 4px;
  padding: 2px 10px;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  z-index: 1;
}

#contents .search_item p.gift {
  position: absolute;
  top: 0;
  right: 8px;
  padding: 20px 14px;
  background-color: #FB5555;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}

#contents .search_item p.gift::before {
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 49px 0 0;
  border-color: #FB5555 transparent transparent transparent;
}

#contents .search_item p.gift::after {
  position: absolute;
  top: 100%;
  right: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 49px 22px 0;
  border-color: transparent #FB5555 transparent transparent;
}

#contents .search_item p.gift span {
  font-family: Roboto;
  font-size: 1.4rem;
}

#contents .search_item .jobtext a p {
  margin: 24px 0 0;
  padding: 0;
  color: #3D270F;
  font-size: 1.6rem;
}

#contents .search_item .jobtext a {
  padding: 0 16px;
}

#contents .search_item .icon {
  margin-top: 16px;
  padding: 0 16px;
}

#contents .search_item .icon li {
  margin: 8px 0 0 8px;
  display: inline-block;
  font-size: 1.2rem;
  border: none;
  padding: 0;
  white-space: nowrap;
}

#contents .search_item .icon li a {
  position: relative;
  display: inline-block;
  padding: 6px 8px 6px 26px;
  color: #2382C7;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  border: none;
  font-size: 1.2rem;
  line-height: 1;
}

#contents .search_item .icon li a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translateY(-50%);
}

#contents .search_item .info_table {
  margin-top: 24px;
  padding: 0 24px 0;
}

#contents .search_item table {
  margin-top: 24px;
  border-collapse: separate;
  border-spacing: 0 7px;
  color: #3D270F;
  font-size: 1.2rem;
  font-weight: 400;
}

#contents .search_item table > div {
  margin-top: 8px;
}

#contents .search_item table th,
#contents .search_item table td {
  border: none;
}

#contents .search_item table td {
  width: 58%;
}

#contents .search_item table th {
  position: relative;
  width: 35%;
  padding: 8px 8px 8px 32px;
  background-color: #FFF0DB;
  font-weight: 400;
  text-align: left;
}

#contents .search_item th::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 8px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

#contents .search_item th.ic-job::before {
  background-image: url(../../img/sp/icon/job02.svg);
}

#contents .search_item th.ic-wifi::before {
  background-image: url(../../img/sp/icon/wifi02.svg);
}

#contents .search_item th.ic-company::before {
  background-image: url(../../img/sp/icon/company.svg);
}

#contents .search_item th.ic-map::before {
  background-image: url(../../img/sp/icon/map02.svg);
}

#contents .search_item td {
  padding: 8px 8px 8px 16px;
}

#contents .search_item .button {
  width: 100%;
}

#contents .search_item .button a {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 10px 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}

#contents .search_item .button a::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

/* entry_list
----------------------------------------------- */
#contents .entry_list {
  margin: 0 0 10px 0;
}

#contents .entry_list article {
  background-color: #FFFFFF;
  border-bottom: 1px solid #ccc;
  line-height: 1.5;
  padding: 7px 10px 0;
}

#contents .entry_list .img {
  text-align: center;
  padding-bottom: 10px;
}

#contents .entry_list h3 {
  border: 0;
  padding: 0;
  margin-bottom: 5px;
}

#contents .entry_list .state {
  font-weight: bold;
  padding-top: 10px;
}

#contents .entry_list .button {
  text-align: left;
}

#contents .entry_list .button.left {
  margin-left: -16px;
}

#contents .entry_list .button a {
  width: auto;
  margin: 0;
  padding: 4px 16px;
  box-shadow: none;
}

#contents .entry_list .button a + a {
  margin-left: 4px;
}

/* resume_list
----------------------------------------------- */
#contents .sch-detail._resumeList {
  margin: 24px 0 0;
  padding: 16px;
}

.resume_list {
  background-color: #FFFFFF;
}

#contents .resume_list article {
  border-bottom: 1px solid #ccc;
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  line-height: 1.5;
  display: table;
  width: 100%;
  padding-left: 8px;
}

#contents .resume_list label {
  width: 35px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#contents .resume_list label input[type="radio"] {
  margin-right: 3px;
  height: 25px;
  width: 25px;
  vertical-align: middle;
  margin-bottom: 5px;
}

#contents .resume_list .text {
  display: table-cell;
}

#contents .resume_list .text a {
  padding: 15px 20px 15px 0;
  display: block;
  text-decoration: none;
  color: #333;
}

#contents .resume_list .date {
  padding: 0;
  text-align: left;
}

/* mail_list
----------------------------------------------- */
#contents .description + .mail_list {
  border-top: 1px solid #ccc;
}

#contents .mail_list article a {
  color: #333;
}

#contents .mail_list article.open a {
  color: #999;
}

#contents .mail_list article {
  border-bottom: 1px solid #ccc;
  background: url(../../img/sp/arrow.png) no-repeat right;
  background-size: 20px 11px;
  line-height: 1.5;
  display: table;
  width: 100%;
}

#contents .mail_list article a.delete {
  width: 30px;
  display: table-cell;
  background: url(../../img/sp/delete.png) no-repeat 5px center;
  background-size: 16px 16px;
  text-indent: -9999px;
  overflow: hidden;
}

#contents .mail_list article .text {
  display: table-cell;
  padding: 0;
}

#contents .mail_list article .text a {
  padding: 10px 25px 10px 0;
  display: block;
  text-decoration: none;
}

#contents .mail_list article .text .mailtype {
  background-color: #333;
  color: #fff;
  font-size: 85%;
  padding: 3px;
  margin-right: 5px;
}

#contents .mail_list article.open .text .mailtype {
  background-color: #888;
}

#contents .mail_list article .text .title {
  clear: both;
}

#contents .mail_list article .text .content {
  font-size: 90%;
}

#contents .mail_list article .date {
  float: right;
  padding: 0;
}

/* message_list
----------------------------------------------- */
#contents .message_list article > a {
  text-decoration: none;
  color: #333;
  line-height: 1.4;
  display: block;
  padding: 10px;
  background: url(../../img/sp/arrow2.png) no-repeat right center;
  background-size: 20px 11px;
  padding-right: 35px;
}

#contents .message_list {
  background-color: #FFFFFF;
}

#contents .message_list article {
  border-bottom: 1px solid #ccc;
  line-height: 1.5;
}

#contents .message_list article h3 {
  line-height: 1.4;
}

#contents .message_list article .message_content {
  margin: 0 5px 24px 10px;
}

#contents .message_list article .message {
  padding: 10px;
  font-size: 11px;
}

#contents .message_list article .mailtype {
  font-size: 11px;
  padding: 2px 5px;
  margin-right: 5px;
  font-weight: normal;
  background: #ddd;
  border-radius: 3px;
}

#contents .message_list article .maildate {
  font-size: 11px;
}

#contents .message_list article .from {
  font-size: 11px;
}

#contents .message_list article p.file {
  line-height: 1.5;
  background-color: #eee;
}

#contents .message_list article p.file a {
  display: block;
  background: url(../../img/sp/file.png) no-repeat 10px center;
  background-size: 30px 16px;
  padding: 2px 10px 2px 45px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

#contents .message_list article .mail_action {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  background-color: #ddd;
}

#contents .message_list article .mail_action input[type="button"] {
  padding: 5px 10px;
  font-size: 12px;
}

#contents .description + .mail_list {
  margin-top: 16px;
  background: #fff;
}

/*----------------------------------------------------------------【フォーム】*/
/* item検索タブ切り替え
----------------------------------------------- */
#contents #tab2 {
  display: none;
}

#contents h2 .search,
#contents h2 .back {
  float: right;
  padding: 8px 12px;
  display: inline-block;
  background: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

/* フォーム送信ボタン
----------------------------------------------- */
#contents .button {
  margin: 16px 0;
  padding: 0 16px;
  text-align: center;
}

#contents .button-flex {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

#contents .button input {
  appearance: none;
  display: inline-block;
  width: fit-content;
  max-width: 240px;
  margin: 0;
  padding: 14px 40px 14px 32px;
  background-color: #ff7e41;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

#contents .button input + input {
  margin: 0 0 0 16px;
}

#contents .button input.fit {
  width: 240px;
}

#contents .button input.btn_back + input.fit {
  margin: 16px 0 0;
}

#contents .button li + li {
  margin: 16px 0 0;
}

#contents .button input.btn_back + input.btn_regist,
#contents .button input.btn_back + input.btn_check {
  margin-top: 16px;
}

@media screen and (min-width: 560px) {
  #contents .button input.btn_back + input.btn_regist,
  #contents .button input.btn_back + input.btn_check {
    margin-left: 16px;
  }
}

#contents .button input.btn_back {
  padding: 14px 32px 14px 40px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/left-circle-back.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 8px;
  background-size: 16px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  border-radius: 6px;
  box-shadow: none;
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

#contents .button a {
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: inline-block;
  appearance: none;
  background-color: #ff7e41;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

.button-svgIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

#contents .button .not {
  background: #ff7e41;
  color: #fff;
}

#contents .button a + a {
  margin-top: 8px;
}

#contents .button .btn_check {
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

#contents .button .btn_check[disabled="disabled"] {
  width: 95%;
  background-color: #ddd;
  color: #888;
}

#contents .button button {
  cursor: pointer;
  border: solid 1px #ccc;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  outline: none;
  text-align: center;
  font-size: 14px;
  appearance: none;
  background: #fff;
}

/* 入力画面
----------------------------------------------- */
#contents .form {
  margin: 16px 0;
  padding-bottom: 16px;
  background-color: #fff;
}

#contents .form ul li {
  margin: 0 0 16px;
}

#contents .form ul li > span {
  display: block;
  margin: 0 0 8px;
  padding: 8px;
  background-color: #fff0db;
  color: #3d270f;
  font-size: 1.6rem;
  font-weight: 700;
}

#contents .form ul li > span + p {
  padding: 8px;
}

/* フォームパーツ */
#contents .form ul li input[type="text"],
#contents .form ul li input[type="email"],
#contents .form ul li input[type="number"],
#contents .form ul li input[type="tel"],
#contents .form ul li input[type="password"],
#contents .form ul li select,
#contents .form ul li textarea {
  appearance: none;
  padding: 8px;
  color: #3D270F;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

#contents .form input[name=zip1] {
  margin-right: 4px;
}

#contents .form .zip-mark {
  margin-right: 8px;
  font-size: 1.6rem;
}

#contents .form select[name=add_sub],
#contents .form select[name=has_add_sub] {
  margin-left: 8px;
}

#contents .form ul li select {
  display: inline-block;
  min-width: 100px;
  padding-right: 30px;
  appearance: none;
  background-image: url(../../img/sp/icon/arrow/bottom.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
  color: #828282;
}

#contents .form ul li select + #contents .form ul li select {
  margin-left: 8px;
}

#contents .form ul li p.fix-height select,
#contents .form ul li p.fix-height input {
  margin: 0 8px 0 0;
  height: 40px;
}

#contents .form ul li textarea {
  width: 90%;
}

#contents .form ul li input[type="button"] {
  display: block;
  width: 200px;
  margin: 16px 0;
  padding: 6px 16px 6px 24px;
  background-color: #fff;
  background-image: url(../../img/sp/icon/map02.svg);
  background-repeat: no-repeat;
  background-position: top 50% left 16px;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

input[type=radio] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 1px;
  width: 16px;
  height: 16px;
  margin: 0;
  background-color: #F2F2F2;
  border-radius: 50%;
  transform: translateY(-50%);
}

input[type=radio]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

input[type=radio]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #3D270F;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

input[type=checkbox]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

input[type=checkbox]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  width: 7px;
  height: 14px;
  border-radius: 4px;
  transform: rotate(40deg);
  border-bottom: 3px solid #3D270F;
  border-right: 3px solid #3D270F;
}

#contents .form ul li .check label,
#contents .form ul li .radio label,
#contents .form ul li > p label {
  position: relative;
  display: inline-block;
  margin: 0 12px 0 0;
  padding-left: 24px;
}

#contents .form ul li .check {
  padding-bottom: 8px;
}

#contents .form ul li .check label {
  width: 100%;
}

#contents .form ul li .check label + label {
  margin-top: 8px;
}

#contents .form ul li .addHopeList {
  position: relative;
  margin: 16px 0;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  color: #828282;
}

#contents .form ul li .addHopeList span {
  position: absolute;
  top: 50%;
  right: 8px;
  display: inline-block;
  padding: 6px 12px 6px;
  background-color: #FFFFFF;
  border-radius: 6px;
  background-image: url(../../img/sp/icon/close02.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 16px 16px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.2rem;
  font-weight: 700;
  transform: translateY(-50%);
}

/* エラーフラグ */
#contents .form ul li .validate input[type="text"],
#contents .form ul li .validate input[type="password"],
#contents .form ul li .validate textarea,
#contents .form ul li .validate select {
  background-color: #FFF2F2;
  box-shadow: 0px 0px 6px #FB5555;
}

#contents .form ul li .error {
  padding: 0;
  margin: 0 5px 16px;
  border: none;
  line-height: 1.4;
  font-size: 1.2rem;
  background: none;
}

#contents .form ul li.message-err .error {
  padding: 0 0 0 8px;
}

/* エラーメッセージ包括 */
#contents .error {
  line-height: 1.5;
  color: #ff3333;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ff3333;
  background-color: #fff;
}

/* 登録ヒント */
#contents .form ul li span.hint {
  background: transparent;
  color: #666;
  margin: 8px 0 0 5px;
  font-size: 10px;
  display: block;
  line-height: 1.4;
  font-weight: normal;
}

#contents .form ul li span.hint + input,
#contents .form ul li span.hint + textarea,
#contents .form ul li span.hint + select {
  margin-top: 8px;
}

/* 必須マーク */
#contents .form ul li span.requisite {
  margin-left: 8px;
  padding: 4px 6px;
  background-color: #FB5555;
  border-radius: 4px;
  color: #ffffff;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1;
}

/* メールアドレス仮登録 */
#contents .form .resMail {
  border: 1px solid #ff3333;
  padding: 10px;
  margin: 5px;
  color: #ff3333;
}

/* 確認画面
----------------------------------------------- */
#contents .form_check {
  margin: 0 0 10px 0;
  padding: 16px 8px;
  background-color: #FFFFFF;
}

#contents .form_check ul {
  margin: 0 0 20px;
}

#contents .form_check ul li {
  border-bottom: 1px solid #ccc;
  padding: 7px 10px;
  line-height: 1.5;
}

#contents .form_check ul li span {
  color: #3D270F;
  font-weight: 700;
}

#contents .form_check ul li p {
  font-size: 1.6rem;
  padding: 0 8px;
}

/*----------------------------------------------------------------【メッセージ】*/
#contents .message {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0;
}

#contents .message p {
  margin: 5px 0;
}

#contents .message ul {
  text-align: center;
  padding: 10px 0;
}

#contents .message ul li a {
  display: inline-block;
  width: 240px;
  padding: 14px 24px 14px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-main.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

#contents .message ul li + li a {
  margin-top: 10px;
}

/*----------------------------------------------------------------【エラーメッセージ】*/
#contents .error_message,
#contents .resMail {
  margin-top: 28px;
  padding: 24px;
  background-color: #FFFFFF;
  border: 1px solid #FB5555;
  border-radius: 4px;
  color: #FB5555;
  font-size: 1.6rem;
}

#contents .error_message p {
  margin: 5px 0;
}

#contents .error_message ul {
  text-align: center;
  padding: 10px 0;
}

#contents .error_message ul li a {
  display: inline-block;
  width: 240px;
  padding: 11px 24px 11px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-main.svg);
  background-position: top 50% right 16px;
  background-repeat: no-repeat;
  background-size: 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

#contents .error_message ul li + li a {
  margin-top: 10px;
}

/*----------------------------------------------------------------【ページャー】*/
#contents .search_page {
  padding: 20px 0;
  text-align: center;
}

#contents .search_page p {
  font-size: 12px;
  margin: 0 0 5px 0;
}

#contents .search_page p span {
  font-weight: bold;
  font-size: 14px;
  margin: 0 3px;
}

#contents .search_page li {
  line-height: 24px;
  display: inline;
  padding: 3px 0;
}

#contents .search_page li.focus {
  padding: 3px 7px;
  font-weight: bold;
}

#contents .search_page li a {
  text-decoration: none;
  padding: 3px 7px;
  color: #333;
  border: 1px solid #ddd;
}

#contents .search_page li.back a {
  background: #ddd;
}

#contents .search_page li.page a {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
}

#contents.search_pagelia:hover {
  background: #333;
  color: #eee;
  border: 1px solid #000;
}

/* ページャー
----------------------------------------------- */
#contents .search_page {
  margin: 16px 0 0;
}

#contents .search_page p {
  color: #3D270F;
  font-size: 1.2rem;
}

#contents .search_page p span {
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 700;
}

#contents .search_page ul {
  margin: 24px -16px 0;
  text-align: center;
}

#contents .search_page ul li {
  position: relative;
  display: inline-block;
  line-height: 1;
}

#contents .search_page ul li.page {
  font-family: Roboto;
}

#contents .search_page ul li.back {
  margin-right: 8px;
}

#contents .search_page ul li.next {
  margin-left: 8px;
  margin-right: 0;
}

#contents .search_page ul li.back a,
#contents .search_page ul li.next a {
  padding: 8px 24px 8px 12px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

#contents .search_page ul li.back a {
  padding: 8px 12px 8px 24px;
}

#contents .search_page ul li.back a svg {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

#contents .search_page ul li.next a svg {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

#contents .search_page ul li.focus {
  padding: 8px 11.42px;
  background: #FF7E41;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: Roboto;
  font-weight: 700;
}

#contents .search_page ul li a {
  display: inline-block;
  padding: 8px 11.42px;
  background: #F2F2F2;
  border-radius: 4px;
  color: #828282;
  font-weight: 700;
  text-decoration: none;
}

/*----------------------------------------------------------------【sub_menu】*/
#contents ul.sub_menu {
  padding: 10px;
  margin-bottom: -1px;
  border-bottom: 1px solid #ccc;
}

#contents ul.sub_menu li {
  display: inline-block;
  margin: 0 2px;
}

#contents ul.sub_menu li a {
  display: inline-block;
  padding: 8px 12px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

/* ニュース
----------------------------------------------- */
#contents .contents_box.news,
#contents .contents_box.page {
  margin-top: 32px;
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 0;
}

#contents .contents_box.news h2,
#contents .contents_box.page h2 {
  position: relative;
  margin-bottom: 0;
  padding: 0;
  background: none;
  border-bottom: none;
  border: none;
  color: #3D270F;
  font-size: 2.2rem;
  text-align: center;
  text-decoration: none;
}

#contents .contents_box.news h2 svg,
#contents .contents_box.page h2 svg {
  margin-right: 8px;
}

#contents .contents_box.news h2 a,
#contents .contents_box.page h2 a {
  color: #3D270F;
  text-decoration: none;
}

#contents .contents_box.news .body,
#contents .contents_box.page .body {
  padding: 0;
}

#contents .contents_box.news ul,
#contents .contents_box.page ul {
  margin-top: 16px;
}

#contents .contents_box.news ul li,
#contents .contents_box.page ul li {
  position: relative;
  display: flex;
  min-height: 48px;
  align-items: center;
  margin-bottom: 8px;
  padding: 12px 50px 12px 12px;
  color: #2382C7;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.1);
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: none;
}

#contents .contents_box.news ul li:hover,
#contents .contents_box.page ul li:hover {
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.2), rgba(150, 198, 232, 0.2)), #FFFFFF;
}

#contents .contents_box.news ul li::after,
#contents .contents_box.page ul li::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/arrow/right-circle.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

#contents .contents_box.news ul li a,
#contents .contents_box.page ul li a {
  color: #2382C7;
  text-decoration: none;
}

#contents .contents_box.news p,
#contents .contents_box.page p {
  padding: 0;
}

#contents .contents_box.page {
  margin: 32px 0 0;
}

#contents .contents_box.page .link_list ul li {
  background-image: none;
}

#contents .contents_box.page .text {
  padding: 0;
}

#contents .contents_box.page .text a {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 48px;
  align-items: center;
  padding: 12px 50px 12px 12px;
  font-size: 1.2rem;
}

/*----------------------------------------------------------------【ニュース本文】*/
#contents .news_text {
  padding: 20px 10px;
  line-height: 1.4;
}

#contents .news_text p {
  margin: 5px 0;
}

#contents .news_text ul {
  text-align: center;
  padding: 10px 0;
}

#contents .news_text ul li a {
  width: 80%;
  padding: 7px 20px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  outline: none;
  text-align: center;
  font-size: 14px;
  appearance: none;
  background: #fff;
  border: solid 1px #ccc;
}

#contents .news_text ul li + li a {
  margin-top: 10px;
}

/*----------------------------------------------------------------【ページ本文】*/
/* ページ本文
----------------------------------------------- */
.main .page_text {
  padding: 10px;
  background: #ffffff;
  font-size: 10px;
  line-height: 1.4em;
}

.main .page_text h3 {
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 0 1em;
  background: #555;
  color: #fff;
}

.main .page_text h4 {
  font-size: 12px;
  font-weight: bold;
  border: none;
  padding: .5em 1em;
  margin: 0 0 1em;
  background: #eee;
  color: #333;
}

.main .page_text ul {
  margin: 1em 0 2em 2em;
}

.main .page_text ul li {
  list-style: disc outside;
  margin: 0 0 .5em;
}

.main .page_text ol {
  margin: 1em 0 2em 2em;
}

.main .page_text ol li {
  list-style: desimal outside;
  margin: 0 0 .5em;
}

.main .page_text p {
  margin: 0 0 2em;
  font-size: 1.6rem;
  line-height: 1.48;
}

.main .page_text .info_table {
  padding: 0;
}

.qj-qanda {
  margin: 10px 0px 10px;
}

.qj-qanda dl {
  margin: 0 0 10px;
}

.qj-qanda dl dt {
  position: relative;
  padding: 0 0 0 30px;
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 1.3em;
}

.qj-qanda dl dd {
  position: relative;
  padding: 0 0 10px 30px;
  margin: 0 0 10px;
  border-bottom: 1px dashed #bbb;
  font-size: 1.6rem;
  line-height: 1.48;
}

.qj-qanda dl dd::after,
.qj-qanda dl dt::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  border-radius: 2px;
}

.qj-qanda dl dt::after {
  content: "Q";
  background-color: #2382c7;
}

.qj-qanda dl dd::after {
  content: "A";
  background-color: #ff7e41;
}

.qj-pageBtn {
  text-align: center;
  margin: 0 0 20px;
}

.qj-pageBtn a {
  display: inline-block;
  padding: 1em 2em;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  text-decoration: none;
  line-height: 1.3em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.qj-pageBtn a + a {
  margin: 0 0 0 .8em;
}

.qj-pagebtna:hover {
  opacity: .7;
  color: #fff;
}

/*----------------------------------------------------------------【インタビュー】*/
#contents .interview-wrap {
  padding: 24px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
  border: none;
}

#contents .interview-wrap .interview-heading {
  margin-bottom: 16px;
  text-align: center;
}

#contents .interview-wrap .interview-heading svg {
  margin-right: 8px;
}

#contents .interview-wrap .item_list article {
  width: 100%;
  margin: 0;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(35, 130, 199, 0.6);
  text-decoration: none;
}

#contents .interview-wrap .item_list article a {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 16px;
}

#contents .interview-wrap .item_list article + article {
  margin-top: 8px;
}

#contents .interview-wrap .item_list article .img {
  width: 70px;
}

#contents .interview-wrap .item_list article .img img {
  object-fit: contain;
  width: 70px;
  height: 50px;
  border: none;
}

#contents .interview-wrap .item_list article .text {
  margin-left: 8px;
}

#contents .interview-wrap .item_list article .text h3 {
  width: 100%;
  margin-top: 8px;
  color: #2382C7;
  font-size: 16px;
}

#contents .interview-wrap .item_list article .cname {
  display: block;
  width: 100%;
  margin: 0;
  color: #000000;
  font-size: 1.2rem;
}

/* メイン：企業インタビュー(詳細)
----------------------------------------------- */
#contents .interview_detail .interview_photo {
  margin-top: 24px;
  background-color: #fffdf4;
  text-align: center;
}

#contents .interview_detail .interview_photo img {
  object-fit: contain;
  width: 320px;
  height: 140px;
}

#contents .interview_detail .interview_description {
  position: relative;
  width: calc(100% - 90px);
  margin: 24px 0 0 90px;
  padding: 16px 24px;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 1.8rem;
  font-weight: 700;
}

#contents .interview_detail .interview_description::before {
  content: "";
  position: absolute;
  top: 50%;
  right: calc(100% + 16px);
  width: 72.5px;
  height: 65px;
  background-image: url(../../img/sp/top/icon-taxi.png);
  background-repeat: no-repeat;
  background-size: 72.5px 65px;
  transform: translateY(-50%);
}

#contents .interview_detail .interview_description::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14.5px 15px 14.5px 0;
  border-color: transparent #FFD0B9 transparent transparent;
  transform: translateY(-50%);
}

#contents .interview_detail .info_list {
  margin-top: 24px;
  padding: 16px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
}

#contents .interview_detail .info_list ul {
  background: #fff;
  padding: 16px 12px;
  border-radius: 4px;
}

#contents .interview_detail .info_list li {
  padding: 0;
  border-bottom: none;
}

#contents .interview_detail .info_list ul li .info_list_heading {
  display: block;
  margin: 8px 0;
  padding: 0;
  color: #2382C7;
  font-size: 1.6rem;
}

#contents .interview_detail .info_list p {
  margin: 0 0 24px;
  padding: 0;
  font-size: 1.6rem;
}

#contents .interview_detail .info_list p img {
  display: block;
  object-fit: contain;
  width: 100%;
  max-width: 310px;
  height: 180px;
  margin: 8px auto;
}

#contents .interview_detail .interview_date {
  margin: 40px 0 0;
  font-size: 1.2rem;
  text-align: right;
}

/*----------------------------------------------------------------【質問詳細】*/
#contents .faq_item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

#contents .faq_item .head_info {
  margin-bottom: 5px;
}

#contents .faq_item .head_info .item_img {
  float: left;
  width: 45px;
}

#contents .faq_item .head_info .item_img img {
  width: 35px;
  height: 35px;
}

#contents .faq_item .head_info .item_img .q {
  background: url(../../img/sp/qa_q.gif) no-repeat left top;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  display: block;
}

#contents .faq_item .head_info .item_img .a {
  background: url(../../img/sp/qa_a.gif) no-repeat left top;
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  display: block;
}

#contents .faq_item .head_info .user_name {
  margin-left: 45px;
}

#contents .faq_item .head_info .time {
  display: block;
  font-size: 12px;
}

#contents .faq_item span.img {
  display: block;
  margin-top: 10px;
}

#contents .faq_item .supplement {
  margin-top: 10px;
}

#contents .faq_item ul.outline {
  border-top: 1px dashed #999;
  margin-top: 10px;
}

#contents .faq_item ul.outline li {
  width: 49%;
}

#contents .faq_item ul.outline li.point {
  text-align: left;
}

#contents .faq_item ul {
  padding: 5px 0;
}

#contents .faq_item ul li {
  display: inline-block;
  font-size: 12px;
}

#contents .faq_item ul li {
  width: 49%;
  display: inline-block;
  font-size: 12px;
}

#contents .faq_item ul li.point {
  text-align: right;
}

#contents.faq_itemulli:only-of-type {
  display: block;
  width: auto;
}

#contents .faq_item .faq_category {
  font-size: 12px;
}

#contents .faq_item .thank {
  border: 1px dotted #ff7e7e;
  padding: 10px;
  margin-top: 5px;
}

#contents .faq_item .faq_box h3 {
  margin-bottom: 5px;
}

#contents .faq_item .faq_box {
  font-size: 16px;
  word-break: break-all;
}

/* この質問に対する回答
----------------------------------------------- */
#contents #answer_list {
  margin-top: 5px;
}

#contents #answer_list h2 a {
  font-size: 14px;
  margin-left: 5px;
}

#contents #answer_list ul {
  border: 0;
  margin: 0;
  padding: 5px 0 0;
}

#contents #answer_list li {
  display: block;
  text-align: right;
  width: auto;
}

#contents #answer_list .thank h3 {
  font-size: 12px;
}

#contents #answer_list .controller4Answer {
  float: left;
}

/*----------------------------------------------------------------【お祝い金申請】*/
#contents .item_list table td.giftBotton a {
  background: #666;
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 3px 0;
}

.gift-btn {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 10px 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

.gift-btn::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

/*----------------------------------------------------------------検索一覧フォーム*/
#contents .sch-from_contents {
  margin-top: 34px;
}

#contents .sch-from_contents dt {
  position: relative;
  padding: 6px 12px 6px 36px;
  background-color: #FFF0DB;
  font-size: 1.8rem;
  font-weight: 700;
}

#contents .sch-from_contents dt span {
  color: #FF7E41;
}

#contents .sch-from_contents dt::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

#contents .sch-from_contents dt.ic-job::before {
  background-image: url(../../img/sp/icon/job.svg);
}

#contents .sch-from_contents dt.ic-wifi::before {
  background-image: url(../../img/sp/icon/wifi.svg);
}

#contents .sch-from_contents dt.ic-feature::before {
  background-image: url(../../img/sp/icon/feature.svg);
}

#contents .sch-from_contents dt.ic-key::before {
  background-image: url(../../img/sp/icon/key.svg);
}

#contents .sch-from_contents dt.ic-company::before {
  background-image: url(../../img/sp/icon/company02.svg);
}

#contents .sch-from_contents dt.ic-map::before {
  background-image: url(../../img/sp/icon/map.svg);
}

#contents .sch-from_contents dt.ic-money::before {
  background-image: url(../../img/sp/icon/money.svg);
}

#contents .sch-from_contents dd {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}

#contents .sch-from_contents dd label {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 24px;
  color: #3D270F;
  cursor: pointer;
  font-size: 1.6rem;
}

#contents .sch-from_contents dd label:last-of-type {
  margin-bottom: 0;
}

#contents .sch-from_contents dd label input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #F2F2F2;
  border-radius: 4px;
}

#contents .sch-from_contents dd label input[type=checkbox]:checked {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

#contents .sch-from_contents dd label input[type=checkbox]:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  width: 7px;
  height: 14px;
  border-radius: 4px;
  transform: rotate(40deg);
  border-bottom: 3px solid #3D270F;
  border-right: 3px solid #3D270F;
}

#contents .sch-from_contents input[type="submit"].btn_search {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 48px;
  padding: 0 0 0 10px;
  background: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;
}

#contents .sch-from_contents input[name=free],
#contents .sch-from_contents input[name=cuser_name] {
  appearance: none;
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 8px 24px;
  background: #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
}

#contents .sch-from_contents input[name=free]::placeholder {
  color: #828282;
}

#contents .sch-from_contents .button {
  position: relative;
  width: 240px;
  height: 48px;
  margin: 40px auto 0;
  padding: 0;
}

#contents .sch-from_contents .button svg {
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  z-index: 1;
}

#contents .sch-from_contents select {
  width: 184px;
  height: 40px;
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  margin: 0 16px 16px 0;
  padding: 0 10px;
  font-size: 1.6rem;
  color: #828282;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  background-image: url(../../img/sp/icon/arrow/bottom.svg);
  background-repeat: no-repeat;
  background-position: top 50% right 8px;
  background-size: 16px;
}

#contents .sch-from_contents select + select {
  margin: 0 16px 0 0;
}

#contents .sch-from_contents select[name=salary_type] {
  margin: 0 16px 0 0;
}

#contents .sch-from_contents .japan_select {
  position: relative;
  display: block;
  width: 184px;
}

#contents .sch-from_contents .money_select {
  position: relative;
}

#contents .sch-from_contents .sub-txt {
  font-size: 1.6rem;
}

#contents .sch-from_contents .salary_select {
  position: relative;
  margin-top: 16px;
}

#contents .sch-detail {
  position: relative;
  margin: 24px 16px 0;
  padding: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

#contents .sch-detail .job_h2 {
  display: flex;
  padding: 20px 100px 0 16px;
  border-radius: 4px 4px 0px 0px;
  color: #3D270F;
  font-size: 2.2rem;
  line-height: 1.4;
}

#contents .sch-detail .list_pickup.text .pickup {
  left: 0;
  border-radius: 0;
}

#contents .sch-detail .list_pickup::before {
  content: "PICKUP";
  position: absolute;
  top: 0;
  left: 66px;
  background: #2382C7;
  border-radius: 4px 0px 0px 0px;
  color: #fff;
  margin-right: 4px;
  padding: 2px 10px;
  font-family: Roboto;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  z-index: 1;
}

#contents .sch-detail p.gift {
  position: absolute;
  top: -8px;
  right: 16px;
  padding: 20px 14px;
  background-color: #FB5555;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
}

#contents .sch-detail p.gift::before {
  position: absolute;
  top: 100%;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 49px 0 0;
  border-color: #FB5555 transparent transparent transparent;
}

#contents .sch-detail p.gift::after {
  position: absolute;
  top: 100%;
  right: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 49px 22px 0;
  border-color: transparent #FB5555 transparent transparent;
}

#contents .sch-detail p.gift span {
  font-family: Roboto;
  font-size: 1.4rem;
}

#contents .sch-detail h2.job_h2 span.cate {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 400;
}

#contents .sch-detail .description {
  margin: 24px 0 0;
  padding: 0 16px;
  color: #3D270F;
  font-size: 1.6rem;
}

#contents .sch-detail .icon {
  margin-top: 16px;
  padding: 0;
}

#contents .sch-detail .icon li {
  margin: 8px 0 0 8px;
  display: inline-block;
  font-size: 1.2rem;
  border: none;
  padding: 0;
  white-space: nowrap;
}

#contents .sch-detail .icon li a {
  position: relative;
  display: inline-block;
  padding: 6px 8px 6px 26px;
  color: #2382C7;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 16px 0px 0px 16px;
  border: none;
  font-size: 1.2rem;
  line-height: 1;
}

#contents .sch-detail .icon li a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translateY(-50%);
}

#contents .sch-detail .info_list {
  margin-top: 24px;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}

#contents .sch-detail .info_list h3 {
  position: relative;
  margin-top: 24px;
  padding: 0 0 0 28px;
  background-color: #ffffff;
  color: #3D270F;
  font-size: 2.2rem;
  font-weight: 700;
}

#contents .sch-detail .info_list h3 svg {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 6px;
}

#contents .sch-detail ul {
  margin-bottom: 16px;
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 400;
}

#contents .sch-detail ul li {
  border-bottom: none;
  padding: 0;
}

#contents .sch-detail ul span {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 16px;
  padding: 8px;
  background-color: #FFF0DB;
  color: #3D270F;
  font-weight: 700;
}

#contents .sch-detail ul span.mt-0 {
  margin-top: 0;
}

#contents .sch-detail span::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 12px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

#contents .sch-detail span.ic-job::before {
  background-image: url(../../img/sp/icon/job02.svg);
}

#contents .sch-detail span.ic-wifi::before {
  background-image: url(../../img/sp/icon/wifi02.svg);
}

#contents .sch-detail span.ic-company::before {
  background-image: url(../../img/sp/icon/company.svg);
}

#contents .sch-detail span.ic-map::before {
  background-image: url(../../img/sp/icon/map02.svg);
}

#contents .sch-detail p {
  margin-top: 8px;
  padding: 0;
}

#contents .sch-detail .button {
  margin: 24px auto 0;
  padding: 0;
  background-color: #FFFFFF;
}

#contents .sch-detail .btn_clip,
#contents .sch-detail .btn_clip.not,
#contents .sch-detail .btn_inquiry,
#contents .sch-detail .btn_inquiry.not {
  position: relative;
  width: 100%;
  padding: 10px 0 10px 10px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  color: #FF7E41;
  font-size: 16px;
  text-align: center;
}

#contents .sch-detail .btn_clip::before,
#contents .sch-detail .btn_clip.not::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/add.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

#contents .sch-detail .btn_inquiry::before,
#contents .sch-detail .btn_inquiry.not::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/mail02.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

#contents .sch-detail .button .btn_entry {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 16px;
  padding: 10px 0;
  background-color: #FF7E41;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}

#contents .sch-detail .button .btn_entry:hover {
  opacity: .8;
}

#contents .sch-detail .button .btn_entry::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 16px;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  transform: translateY(-50%);
}

#contents .sch-detail .btn_clip_out,
#contents .sch-detail .btn_clip_out.not {
  position: relative;
  padding: 10px 10px 10px 16px;
  background-color: #FFFFFF;
  border: 2px solid rgba(54, 59, 64, 0.4);
  box-shadow: none;
  border-radius: 6px;
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  text-align: center;
}

#contents .sch-detail .btn_clip_out::before,
#contents .sch-detail .btn_clip_out.not::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../img/sp/icon/close.svg);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

/* 固定ページ系
----------------------------------------------- */
.page-contents {
  margin-top: 16px;
  padding-bottom: 30px;
  background: #fffdf4;
}

.page-contents_heading {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.page-contents_heading img {
  position: absolute;
  bottom: 0;
}

.page-contents_heading h4 {
  position: relative;
  width: 100%;
  margin-left: 90px;
  padding: 18px 22px;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 1.8rem;
  text-align: center;
}

.page-contents_heading h4::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14.5px 15px 14.5px 0;
  border-color: transparent #FFD0B9 transparent transparent;
  transform: translateY(-50%);
}

.page-contents_head h3 {
  margin: 0 -16px;
  padding: 0;
  border-left: none;
  text-align: center;
}

.page-contents_head h3 img {
  width: 100%;
}

.page-contents_head p {
  margin-top: 24px;
  padding: 0;
}

.page-contents_point, .page-contents_step, .page-contents_qa {
  margin-top: 28px;
}

.page-contents_step h4 {
  position: relative;
  background-color: #FFD0B9;
  border-radius: 6px;
}

.page-contents_qa h4 {
  position: relative;
  background-color: #FFD0B9;
  border-radius: 6px;
  color: #3D270F;
  font-size: 1.8rem;
  font-weight: 700;
}

.page-contents_foot {
  margin-top: 48px;
}

.page-contents_foot .page-entryBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 24px 40px 24px 24px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: none;
  word-break: keep-all;
  text-align: center;
}

.page-contents_foot .page-entryBtn svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.page-contents_foot .page-registBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 18px 18px 18px 44px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: none;
}

.page-contents_foot .page-registBtn:hover {
  opacity: .8;
}

.page-contents_foot .page-registBtn svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.page-pointItems,
.page-stepItems,
.page-qaItems {
  margin-top: 24px;
}

.page-stepItem {
  padding-bottom: 16px;
}

.page-stepItem + .page-stepItem {
  margin-top: 40px;
}

.page-stepItem_head {
  position: relative;
  display: block;
  background-color: #FFF0DB;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}

.page-stepItem:not(:first-child) .page-stepItem_head::before {
  content: " ";
  position: absolute;
  top: -43%;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: 16px;
  border-style: solid;
  border-width: 16px 16px 0 16px;
  border-color: #FFD0B9 transparent transparent transparent;
  transform: translate(-50%, 43%);
}

.page-stepItem_head span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background-color: #FFD0B9;
  font-size: 2.2rem;
}

.page-stepItem_head p {
  padding: 16px;
}

.page-stepItem_head .page-registBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 244px;
  height: 40px;
  margin: 30px auto 0;
  padding: 0 16px 0 24px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
}

.page-stepItem_head .page-registBtn svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.page-stepItem_txt {
  margin-top: 16px;
}

.page-stepItem_txt strong {
  color: #FF7E41;
}

.page-stepItem_txt span {
  display: block;
  margin-top: 24px;
  font-size: 1.2rem;
}

.page-qaItems_row + .page-qaItems_row {
  margin-top: 24px;
}

.page-qaItems dl {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
}

.page-qaItems dt {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 106px;
  padding: 24px 24px 24px 46px;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px 4px 0 0;
  font-size: 1.8rem;
  font-weight: 700;
}

.page-qaItems dt::before {
  position: absolute;
  top: 48%;
  left: 16px;
  content: "Q";
  color: #2382C7;
  font-family: Roboto;
  font-size: 2.4rem;
  font-weight: 700;
  transform: translateY(-48%);
}

.page-qaItems dd {
  position: relative;
  width: 100%;
  padding: 24px 24px 24px 46px;
  font-size: 1.8rem;
}

.page-qaItems dd::before {
  position: absolute;
  top: 18px;
  left: 16px;
  content: "A";
  color: #FF7E41;
  font-family: Roboto;
  font-size: 2.4rem;
  font-weight: 700;
}

.page-qaItems dd span {
  display: block;
  margin-top: 24px;
  font-size: 1.2rem;
  line-height: 1.6;
}

.page-pointItem {
  width: 100%;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
  border-radius: 4px;
}

.page-pointItem + .page-pointItem {
  margin-top: 16px;
}

.page-pointItem_capHead {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  color: #2382C7;
  font-family: Roboto;
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
}

.page-pointItem_capHead span {
  margin-top: -15px;
  font-size: 5.5rem;
  line-height: 1;
}

.page-pointItem figure {
  margin: 0;
}

.page-pointItem figure img {
  width: 100%;
  margin-top: 20px;
  vertical-align: bottom;
}

.page-pointItem figcaption {
  padding: 0 24px;
  font-size: 1.8rem;
}

.page-pointItem figcaption strong {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 62px;
  margin-bottom: 16px;
  font-size: 2.6rem;
  line-height: 1.2;
}

.saiyo-pointItems {
  margin-top: 24px;
}

.saiyo-pointItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 200px;
  background-color: #FFF0DB;
  border-radius: 4px;
  line-height: 1.48;
}

.saiyo-pointItem + .saiyo-pointItem {
  margin-top: 16px;
}

.saiyo-pointItem strong {
  font-size: 4rem;
  text-align: center;
}

.saiyo-pointItem_txtLg {
  color: #FB5555;
  font-family: Roboto;
  font-size: 12rem;
  line-height: 1;
}

.saiyo-pointItem_txtSm {
  font-size: 3rem;
}

.saiyo-pointItem_crMain {
  color: #FF7E41;
}

.saiyo-point_txt p {
  margin-top: 32px;
}

.saiyo-point_txt p span {
  color: #FF7E41;
}

.saiyo-point_txt_large {
  font-size: 2.4rem;
  text-align: center;
  word-break: keep-all;
}

/* 固定ページ凡用
----------------------------------------------- */
.other .bread {
  margin: 24px -16px;
  padding: 0 16px 16px;
  background: #fffdf4;
}

.other h2 {
  margin-top: 24px;
  padding-left: 0;
}

.page_box {
  background: #fff;
  border: 1px solid rgba(54, 59, 64, 0.4);
  border-radius: 4px;
  padding: 24px;
  margin: 24px 0 0;
}

.page_box dl {
  margin-bottom: 30px;
}

.page_box dt {
  margin: 24px 0 10px;
}

.page_box p {
  margin: 0 0 16px;
}

.page_box ul {
  padding-left: 1em;
}

.page_box ul li {
  margin-bottom: 4px;
  list-style: disc;
}

/* 詳細ページテーブル（info_table）
----------------------------------------------- */
.info_table {
  margin-top: 24px;
}

.info_table table {
  color: #3D270F;
  font-size: 1.6rem;
  font-weight: 400;
}

.info_table table > div {
  margin-top: 8px;
}

.info_table table th,
.info_table table td {
  border: 1px solid #E0E0E0;
}

.info_table table th {
  position: relative;
  width: 25%;
  padding: 8px;
  background-color: #FFF0DB;
  font-weight: 700;
}

.info_table td {
  padding: 8px;
}

#contents .sch-detail ul span.googlemap,
.googlemap {
  position: relative;
  display: inline;
  width: initial;
  margin-left: 8px;
  padding: 0 0 0 14px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/map.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 12px 12px;
  font-weight: 400;
}

/* ボタン
----------------------------------------------- */
#contents .button button {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 16px 11px;
  background-color: #FF7E41;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
}

#contents .button a.btn-main {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 24px 11px 16px;
  background-color: #FF7E41;
  background-image: url(../../img/sp/icon/arrow/right-circle-sub.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(54, 59, 64, 0.4);
  color: #FFFFFF;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
}

#contents .button a.btn-main._note {
  padding: 11px 16px 11px 24px;
  background-image: url(../../img/sp/icon/note03.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
}

#contents .button a.btn-sub {
  position: relative;
  display: block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 24px 11px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-main.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: none;
  color: #FF7E41;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
}

#contents .button .btn-sub_mini {
  position: relative;
  display: block;
  margin: auto;
  padding: 8px 12px 8px;
  background-color: #FFFFFF;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: none;
  color: #FF7E41;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
}

#contents .button .btn-sub_mini._toggle {
  margin: auto -16px auto auto;
}

#contents .button a.btn-sub_back {
  position: relative;
  display: block;
  width: 100%;
  max-width: 240px;
  margin: auto;
  padding: 11px 16px 11px 24px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/left-circle-main.svg);
  background-repeat: no-repeat;
  background-position: center left 16px;
  background-size: 16px 16px;
  border: 2px solid #FF7E41;
  border-radius: 6px;
  box-shadow: none;
  color: #FF7E41;
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  text-decoration: none;
}

#contents .button a.btn-sub_back._w320 {
  max-width: 320px;
}

#contents .button a.btn-main + a.btn-sub,
#contents .button a.btn-main + a.btn-main,
#contents .button a.btn-sub + a.btn-sub {
  margin-top: 16px;
}

button.btn-Border,
#contents .button a.btn-Border {
  display: block;
  width: auto;
  margin: 16px 0;
  padding: 6px 16px;
  background-color: #fff;
  border: 2px solid #ff7e41;
  border-radius: 6px;
  color: #ff7e41;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
}

button.btn-Border._shadowNone,
#contents .button a.btn-Border._shadowNone {
  box-shadow: none;
}

.btn-unsubscribe,
#contents .button input.btn-unsubscribe {
  position: relative;
  display: block;
  width: 240px;
  margin: auto;
  padding: 10px 24px 10px 16px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/arrow/right-circle-gray.svg);
  background-repeat: no-repeat;
  background-position: center right 16px;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  box-shadow: none;
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

#contents .mail_list article .text .mailtype,
#contents .message_list article .mailtype {
  color: #2382c7;
  background: linear-gradient(0deg, rgba(150, 198, 232, 0.3), rgba(150, 198, 232, 0.3)), #FFFFFF;
}

.btn-Shadow {
  box-shadow: 0px 0px 6px rgba(54, 59, 64, 0.4);
}

.form-Alart_Normal {
  background: #FFFFFF;
  border: 1px solid rgba(54, 59, 64, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
}

#contents .contents_box.news.mt-0 {
  margin-top: 0;
}

.btn_cuser_inquiry.not {
  margin: 0 0 24px;
}

#contents .sch-detail._p-16 {
  margin: 24px 0 0;
  padding: 0 16px;
}

#contents .contents_box.news .info_list li {
  border-bottom: none;
}

#contents .contents_box.news .message {
  border: none;
}

.description .att {
  color: #3d270f;
  font-family: Roboto;
  font-size: 2rem;
  font-weight: 700;
}

#contents .info-fixedFoot {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  width: 100%;
  padding: 16px 12px;
  background-color: #DFEEF8;
  box-shadow: 0px 0px 4px rgba(54, 59, 64, 0.4);
  z-index: 10;
}

#contents .info-fixedFoot .btn_tel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 104px;
  height: 40px;
  padding: 0 8px 0 20px;
  background-color: #FF7E41;
  background-image: url(../../img/sp/icon/phone02.svg);
  background-repeat: no-repeat;
  background-position: center left 8px;
  background-size: 16px 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 374px) {
  #contents .info-fixedFoot .btn_tel {
    min-width: 88px;
  }
}

#contents .info-fixedFoot .btn_entry,
#contents .info-fixedFoot .btn_entry.not {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 104px;
  height: 40px;
  padding: 0 8px 0 20px;
  background-color: #FF7E41;
  background-image: url(../../img/sp/icon/entry.svg);
  background-repeat: no-repeat;
  background-position: center left 8px;
  background-size: 16px 16px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 374px) {
  #contents .info-fixedFoot .btn_entry,
  #contents .info-fixedFoot .btn_entry.not {
    min-width: 96px;
  }
}

#contents .info-fixedFoot .btn_clip,
#contents .info-fixedFoot .btn_clip.not {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 104px;
  height: 40px;
  padding: 0 8px 0 20px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/add.svg);
  background-repeat: no-repeat;
  background-position: center left 8px;
  background-size: 16px 16px;
  border-radius: 6px;
  border: solid 2px #FF7E41;
  color: #FF7E41;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

#contents .info-fixedFoot .btn_clip_out,
#contents .info-fixedFoot .btn_clip_out.not {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 104px;
  height: 40px;
  padding: 0 8px 0 20px;
  background-color: #FFFFFF;
  background-image: url(../../img/sp/icon/close.svg);
  background-repeat: no-repeat;
  background-position: center left 8px;
  background-size: 16px 16px;
  border-radius: 6px;
  border: 2px solid rgba(54, 59, 64, 0.4);
  color: rgba(54, 59, 64, 0.4);
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}

#contents .info-fixedFoot a + a {
  margin-left: 8px;
}

.gift-form select {
  display: block;
}

.gift-form .select-wrap span {
  margin: 0 16px;
}

.gift-form .select-wrap {
  display: flex;
  align-items: center;
}

.gift-form .select-wrap {
  display: block;
}

.gift-form .select-wrap > div:not(:first-child) {
  margin-top: 24px;
}

.gift-form .select-wrap > div {
  display: flex;
  align-items: center;
}
